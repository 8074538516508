import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import useKey from "utils/KeyListener";

import xmark from "imgs/xmark.png";

export default function Modal({ children, title, closeCb }) {
  const close = useCallback(() => {
    if (closeCb) closeCb();
  }, [closeCb]);

  useKey("Escape", false, () => {
    close();
  });

  const modalRoot = document.getElementById("modal-root");

  return ReactDOM.createPortal(
    <div
      className="modal-background"
      onClick={() => {
        close();
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: 30,
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          top: 50,
          width: 800,
          maxWidth: "90%",
          borderRadius: 14,
          display: "flex",
          flexDirection: "column",
          rowGap: 20,
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div style={{ position: "absolute", right: -60, top: 0 }}>
          <img
            alt="closebtn"
            src={xmark}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              height: 20,
              filter: "invert(0.6)",
              cursor: "pointer",
            }}
            onClick={() => close()}
          />
        </div>
        {title && <h2 style={{ color: "#000", marginTop: -11 }}>{title}</h2>}

        {children}
      </div>
    </div>,
    modalRoot
  );
}
