import React, { useState } from "react";
import Button from "components/Button";

import logo from "imgs/logo_white.png";
import xicon from "imgs/x-icon.png";

import { FooterContainer, LogoImage, Flex, FooterRightIcons, FooterColumn, FooterEnd } from "styles/global-style";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterColumn>
        <LogoImage alt="logo" src={logo} />
      </FooterColumn>
      <FooterColumn style={{ display: "inline-block", textAlign: "center" }}>
        <Flex style={{ justifyContent: "center" }}>
          <Button text="Press" click="/press" white menuitem />
          <Button text="Terms & Conditions" click="/tos" white menuitem />
          <Button text="Privacy policy" click="/privacy" white menuitem />
        </Flex>
      </FooterColumn>
      <FooterColumn>
        <FooterRightIcons>
          <SocialMediaIcon src={xicon} invert />
        </FooterRightIcons>
      </FooterColumn>
      <FooterEnd>© {new Date().getFullYear()} LinkinGame - All rights reserved.</FooterEnd>
    </FooterContainer>
  );
}

const SocialMediaIcon = (props) => {
  const [hover, setHover] = useState(false);
  return (
    <img
      src={props.src}
      alt="footeritem"
      style={{
        width: 30,
        verticalAlign: "middle",
        cursor: props.url ? "pointer" : "initial",
        margin: "0px 10px",
        filter: props.invert ? "invert(1)" : "none",
        transform: hover && props.url ? "scale(1.05)" : "scale(1)",
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onClick={() => {
        if (props.url) window.open(props.url, "_blank");
      }}
    />
  );
};
