import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { AuthContext } from "utils/AuthContext";
import { FlexLeftCol, Group } from "styles/global-style";
import Input from "components/Input";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { useNavigate } from "react-router-dom";

export default function Project() {
  const { supabase, user, refresh, roleAccess } = useContext(AuthContext);
  const navigate = useNavigate();

  const [projName] = useState(user.proj.name);
  const [showWeekends, setShowWeekends] = useState(false);

  const nameRef = useRef();

  //----------------------------------------------------

  const submit = useCallback(async () => {
    const input = nameRef.current.value.trim();
    if (input) {
      const newName = input ? input : user.firstname + "'s proj";
      await supabase.from("projects").update({ name: newName }).eq("id", user.current_proj_id);
    }
    refresh();
  }, [supabase, user.current_proj_id, user.firstname, refresh]);

  const deleteProj = useCallback(async () => {
    if (
      window.confirm(
        "DANGER!\n\nYou are about to delete your whole organization, and all associated projects and data. There is no going back after this.\n\nARE YOU ABSOLUTLEY CERTAIN?"
      )
    ) {
      if (window.confirm("Seriously? Just making sure cause I care..\n\nShould I delete your organization?")) {
        // TODO: Delete all proj's uploaded images from Storage
        // const projImgs = await supabase.from("images").select("url").eq("project_id", user.proj.id).eq("type", "project");

        // if (projImgs.data?.length) {
        //   const arr = projImgs.data.map((val) => val.url.split("/").at(-1));
        //   supabase.storage.from("projects").remove(arr);
        // }

        // Start deleting table rows, order important due to db fkeys
        await supabase.from("projects").delete().eq("id", user.proj.id);

        refresh();
        // TODO: navigate to other existing project, or back to orgproj
        navigate("/auth/");
      }
    }
  }, [navigate, refresh, supabase, user]);

  const toggleSetting = useCallback(
    async (name, value, setterCb, forceRefresh) => {
      const setting = user.proj.settings.find((s) => s.name === name);
      setterCb(value);
      if (setting) await supabase.from("settings").update({ value: value }).eq("id", setting.id);
      else await supabase.from("settings").insert({ name: name, value: value, project_id: user.proj.id });
      refresh();
    },
    [user, refresh, supabase]
  );

  //----------------------------------------------------

  useEffect(() => {
    if (user) {
      setShowWeekends(user.proj.settings.find((s) => s.name === "showweekends")?.value === "true");
    }
  }, [user]);

  return (
    <FlexLeftCol>
      <h2>Project</h2>
      <Input caption="Name" ref={nameRef} onChangeDelay={submit} value={projName} white left />
      <Group caption="People settings" white>
        <Checkbox text="Show weekends" checked={showWeekends} onChangeCb={() => toggleSetting("showweekends", !showWeekends, setShowWeekends)} white />
      </Group>
      {roleAccess("admin") && (
        <>
          <p style={{ color: "red", marginTop: 80 }}>
            <b>Dangerzone</b>
          </p>
          <Button text="Delete project" callback={deleteProj} color="red" />
        </>
      )}
    </FlexLeftCol>
  );
}
