import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { AuthContext } from "utils/AuthContext";
import Button from "components/Button";
import Input from "components/Input";
import { Colors, Section, Form } from "styles/global-style";
import validator from "validator";

export default function Forgot(props) {
  const { user, requestPasswordRecovery } = useContext(AuthContext);

  const [inputValid, setInputValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const emailRef = useRef(null);

  const validate = useCallback(() => {
    if (emailRef.current) {
      setInputValid(validator.isEmail(emailRef.current.value.trim()));
    }
  }, []);

  useEffect(() => {
    let email = localStorage.getItem("email");
    if (email) emailRef.current.value = email;
    validate();
  }, [user, validate]);

  const submit = useCallback(() => {
    setIsSubmitting(true);
    if (!user && inputValid) {
      requestPasswordRecovery(emailRef.current.value.trim(), (err) => {
        setIsSubmitting(false);
        setSuccess(true);
      });
    }
  }, [user, inputValid, requestPasswordRecovery]);

  return (
    <Section>
      <h1>Forgot my password</h1>
      {!user && !success && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <Input
            ref={emailRef}
            placeholder="your@email.here"
            onChange={() => {
              validate();
            }}
            white
            autoFocus
          />
          <button type="submit" style={{ display: "none" }} />
          <Button
            text={"Recover password"}
            large
            disabled={!inputValid}
            callback={() => {
              submit();
            }}
            loading={isSubmitting}
          />
        </Form>
      )}
      {success && <h2 style={{ color: Colors.GREEN }}>Recovery sent</h2>}
    </Section>
  );
}
