import React, { useState } from "react";
import { Flex, PADDING } from "styles/global-style";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { MenuItem } from "components/Menus/style";
import ButtonTiny from "components/ButtonTiny";
import Column from "./Column";
import { Draggable } from "@hello-pangea/dnd";

export default function Story({ indexPath, story, index, goalId, deleteStoryCb, addTaskCb, onClickStoryCb, editTaskCb, users }) {
  const [hovering, setHovering] = useState(false);

  const getStyle = (isDragging, draggableStyle) => ({
    marginBottom: PADDING,
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable key={indexPath.join("-")} draggableId={indexPath.join("-")} index={index} type="story">
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getStyle(snapshot.isDragging, provided.draggableProps.style)}
          onMouseOver={() => setHovering(true)}
          onMouseOut={() => setHovering(false)}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: 31,
              fontWeight: "bold",
              color: "#fff",
              textShadow: "rgb(0, 0, 0) 1px 1px 3px",
              cursor: "pointer",
              lineHeight: 1.6,
              marginRight: 10,
              opacity: 1,
              zIndex: 10,
            }}
          >
            <div onClick={() => onClickStoryCb(goalId, story.id)}>{story?.title}</div>
            <Popover className="popover">
              <PopoverButton className="popover-button">
                <ButtonTiny dots />
              </PopoverButton>
              <PopoverPanel className="popover-panel" transition anchor={{ to: "bottom start", padding: "34px" }}>
                {({ close }) => (
                  <MenuItem close={close}>
                    <div style={{ color: "red", fontWeight: "bold" }} onClick={() => deleteStoryCb(indexPath, story.id, story.title)}>
                      Delete story
                    </div>
                  </MenuItem>
                )}
              </PopoverPanel>
            </Popover>
          </div>

          <div
            style={{
              borderRadius: 10,
            }}
          >
            <Flex gap={PADDING / 2} style={{ alignItems: "stretch" }}>
              {story?.columns.length &&
                story.columns.map((col, j) => {
                  return (
                    <Column
                      key={"keycolumn" + j}
                      indexPath={[...indexPath, j]}
                      col={col}
                      storyIndex={index}
                      storyId={story.id}
                      index={j}
                      width={100 / story?.columns.length}
                      isFirst={j === 0}
                      showAdd={hovering}
                      addTaskCb={addTaskCb}
                      editTaskCb={(task) => editTaskCb(task, story, col, j)}
                      users={users}
                    />
                  );
                })}
            </Flex>
          </div>
        </div>
      )}
    </Draggable>
  );
}
