import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorNameToHex from "utils/ColorNameToHex";

export default function Button(props) {
  const { disabled, menuitem, large, link, click, callback, text, loading, active, color, lightmode, width, imgSize, noborder, image } = props;
  const navigate = useNavigate();

  const [hovering, setHovering] = useState(false);
  const [down, setDown] = useState(false);

  const handleMouseEnter = () => {
    if (disabled) return;
    setHovering(true);
  };

  const handleMouseLeave = () => {
    if (disabled) return;
    setDown(false);
    setHovering(false);
  };

  const handleMouseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    if (link) window.open(link);
    if (click) navigate(click);
    if (callback) callback();
    setDown(false);
  };

  const handleMouseDown = () => {
    if (disabled) return;
    setDown(true);
  };

  let _color = lightmode ? "black" : hovering || active ? "#fff" : "#ffffffbb";
  if (color) {
    const nameColor = ColorNameToHex(color);
    if (nameColor) {
      _color = nameColor; // Was name color, turned to solid hex
    } else if (typeof color === "string" && color.charAt(0) === "#") {
      // Hex already but ensure 6 characters and
      const hex = color.splice(1);
      if (hex.length === 3) {
        _color = "#" + hex.charAt(0) + hex.charAt(0) + hex.charAt(1) + hex.charAt(1) + hex.charAt(2) + hex.charAt(2);
      } else {
        _color = "#" + hex.substring(0, 6);
      }
    }
  }

  let _background = "none";
  if (hovering) _background = lightmode ? (color ? _color + "22" : "#00000022") : "#ffffff22";
  else if (active) _background = color ? color : "#ffffff11";

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: noborder ? "none" : _color.length > 4 ? "1px solid " + _color.slice(0, -2) + "22" : "1px solid " + _color,
        background: _background,
        color: _color,
        width: width ? width : "initial",
        height: 32,
        borderRadius: large ? 12 : 5,
        cursor: disabled ? "initial" : "pointer",
        padding: large ? "22px 45px 18px 45px" : "0px 14px",
        margin: menuitem ? "0px 15px" : 0,
        fontSize: large ? 20 : "0.8rem",
        opacity: disabled ? 0.3 : 1,
        userSelect: "none",
        transform: down ? "scale(0.97)" : "scale(1)",
        fontWeight: "bold",
        columnGap: 7,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onClick={handleMouseClick}
    >
      {image && <img alt="buttonImg" src={image} style={{ width: imgSize ? imgSize : 16, opacity: hovering || active ? 1 : 0.8 }} />}
      <div style={{ marginTop: -1, whiteSpace: "nowrap" }}>{text && !loading ? text : "loading..."}</div>
    </div>
  );
}
