import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import { AuthContext } from "utils/AuthContext";
import { Flex, FlexLeftCol } from "styles/global-style";
import useKey from "utils/KeyListener";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import WorkItem from "components/WorkTree/WorkItem";
import KeyboardShortcut from "components/KeyboardShortcut";

import arrow from "imgs/arrow.png";

export default function WorkTree() {
  const { supabase, user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [flatTree, setFlatTree] = useState(); // For linear navigation
  const [editItem, setEditItem] = useState();
  const [activeIndex, setActiveIndex] = useState(-1);

  const localdata = useRef();
  const itemsRef = useRef([]);
  const editItemRef = useRef();
  const itemsById = useRef();
  const prevActiveIndex = useRef();

  const updates = useRef({});
  const updating = useRef(false);
  const tl = useRef();
  const animationDone = useRef();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  // Keyboard shortcuts for editing, navigating, etc.
  useKey("Enter", false, () => {
    if (activeIndex === -1) {
      setActiveIndex(prevActiveIndex.current ? prevActiveIndex.current : 0);
    } else if (!editItem) {
      setEditItem(flatTree[activeIndex]); // Use flatTree for current active item
      itemsRef.current[activeIndex]?.focus();
    } else {
      itemsRef.current[activeIndex]?.blur();
      setEditItem(null);
    }
  });

  useKey("Enter", true, () => {
    addItem(activeIndex);
  });

  useKey("Backspace", false, () => {
    deleteItem();
  });

  // Handle Tab (indentation)
  useKey("Tab", false, async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (updating.current) return;

    let submit = false;
    const currentItem = flatTree[activeIndex];
    const aboveNeighbourItem = flatTree[activeIndex - 1];
    const parentItem = itemsById.current[currentItem.parent_id];

    // Not root item, i.e. has parent, but not directly below that parent
    if (parentItem && parentItem.id !== aboveNeighbourItem.id) {
      let newParent = null;
      for (let i = activeIndex - 1; i >= 0; i--) {
        // Loop upwards
        if (flatTree[i]?.level === currentItem.level) {
          // Previous above sibling with currently same level become parent
          newParent = flatTree[i];
          break;
        }
      }

      let _currentItem = localdata.current.workitems.find((item) => item.id === currentItem.id);
      let _oldParent = localdata.current.workitems.find((item) => item.id === currentItem.parent_id);
      let _newParent = localdata.current.workitems.find((item) => item.id === newParent.id);

      _currentItem.parent_id = _newParent.id;
      _oldParent.children_order = _oldParent.children_order.filter((id) => id !== _currentItem.id);
      if (_oldParent.children_order.length === 0) _oldParent.children_order = null;
      if (!_newParent.children_order) _newParent.children_order = [];
      _newParent.children_order.push(_currentItem.id);

      updates.current["workitems-" + _currentItem.id] = { parent_id: _newParent.id };
      updates.current["workitems-" + _oldParent.id] = { children_order: _oldParent.children_order };
      updates.current["workitems-" + _newParent.id] = { children_order: _newParent.children_order };

      submit = true;
    }

    // Root item, not at top, check for new parent
    if (!parentItem && aboveNeighbourItem) {
      let newParent = null;
      for (let i = activeIndex - 1; i >= 0; i--) {
        // Loop upwards
        if (flatTree[i]?.level === currentItem.level) {
          // Closest sibling above with currently same level become parent
          newParent = flatTree[i];
          break;
        }
      }

      // Alter a copy of the previous raw data fetch and update accordingly, while optimistically updating server
      let _currentItem = localdata.current.workitems.find((item) => item.id === currentItem.id);
      let _newParent = localdata.current.workitems.find((item) => item.id === newParent.id);
      if (!_newParent.children_order) _newParent.children_order = [];
      _currentItem.parent_id = _newParent.id;
      _newParent.children_order.push(_currentItem.id);
      localdata.current.workitems_order = localdata.current.workitems_order.filter((itemId) => itemId !== _currentItem.id);

      updates.current["workitems-" + _currentItem.id] = { parent_id: _newParent.id };
      updates.current["workitems-" + _newParent.id] = { children_order: _newParent.children_order };
      updates.current["goals-" + localdata.current.id] = { workitems_order: localdata.current.workitems_order };

      submit = true;
    }

    if (submit) submitItemMove(currentItem.id);
  });

  // Handle Shift + Tab (outdentation)
  useKey("Tab", "shift", async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (updating.current) return;

    let submit = false;
    const currentItem = flatTree[activeIndex];
    const parentItem = itemsById.current[currentItem.parent_id];

    if (parentItem) {
      let _currentItem = localdata.current.workitems.find((item) => item.id === currentItem.id);
      let _oldParent = localdata.current.workitems.find((item) => item.id === currentItem.parent_id);
      let _newParent = localdata.current.workitems.find((item) => item.id === _oldParent.parent_id);

      // Make grand parent the new parent
      _currentItem.parent_id = _oldParent.parent_id;
      updates.current["workitems-" + _currentItem.id] = { parent_id: _oldParent.parent_id };

      // Find where current item used to be in old parent's children order
      const currentChildIndex = _oldParent.children_order.findIndex((id) => id === _currentItem.id);

      _oldParent.children_order.splice(currentChildIndex, 1);
      if (_oldParent.children_order.length === 0) _oldParent.children_order = null;
      updates.current["workitems-" + _oldParent.id] = { children_order: _oldParent.children_order };

      if (_newParent) {
        if (!_newParent.children_order) _newParent.children_order = [];
        const oldParentChildIndex = _newParent.children_order.findIndex((id) => id === _oldParent.id);
        _newParent.children_order.splice(oldParentChildIndex + 1, 0, _currentItem.id);
        updates.current["workitems-" + _newParent.id] = { children_order: _newParent.children_order };
      } else {
        // New root item
        const newRootPosition = localdata.current.workitems_order.findIndex((itemId) => itemId === _oldParent.id);
        localdata.current.workitems_order.splice(newRootPosition + 1, 0, _currentItem.id);
        updates.current["goals-" + localdata.current.id] = { workitems_order: localdata.current.workitems_order };
      }

      submit = true;
    }

    if (submit) submitItemMove(currentItem.id);
  });

  useKey("ArrowDown", false, (e) => {
    e.preventDefault();
    if (!editItem && activeIndex === -1) {
      setActiveIndex(prevActiveIndex.current ? prevActiveIndex.current : 0);
    } else if (!editItem && activeIndex > -1 && activeIndex < flatTree.length - 1) {
      setActiveIndex(activeIndex + 1); // Navigate down in the flatTree
    }
  });

  useKey("ArrowUp", false, (e) => {
    e.preventDefault();
    if (!editItem && activeIndex === -1) {
      setActiveIndex(prevActiveIndex.current ? prevActiveIndex.current : 0);
    } else if (!editItem && activeIndex > 0) {
      setActiveIndex(activeIndex - 1); // Navigate up in the flatTree
    }
  });

  useKey("Escape", false, (e) => {
    e.preventDefault();
    deselectItems();
  });

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const buildTree = useCallback((data) => {
    localdata.current = data;

    let _itemsById = {};
    data.workitems.forEach((item) => {
      _itemsById[item.id] = { ...item, children: [] };
    });
    itemsById.current = _itemsById;

    let rootItems = [];
    data.workitems.forEach((item) => {
      if (item.parent_id === null) {
        rootItems.push(_itemsById[item.id]);
      } else {
        const parent = _itemsById[item.parent_id];
        if (parent && parent.children.length === 0) {
          if (parent.children_order) {
            parent.children_order.forEach((childId) => {
              const childItem = _itemsById[childId];
              if (childItem) {
                parent.children.push(childItem);
              }
            });
          } else {
            parent.children.push(_itemsById[item.id]);
          }
        }
      }
    });

    // Sort based on workitem_order for root items stored in goal
    let rootItemsSorted = [];
    data.workitems_order?.forEach((itemId) => {
      rootItemsSorted.push(_itemsById[itemId]);
    });

    return rootItemsSorted;
  }, []);

  const flattenTree = useCallback((tree, parent = null, level = 0) => {
    let _flatTree = [];

    tree.forEach((item) => {
      const hasChildren = item.children && item.children.length > 0;

      // Check if all siblings have no children
      const siblings = parent ? parent.children : tree;
      const allSiblingsWithoutChildren = siblings.every((sibling) => !sibling.children || sibling.children.length === 0);

      // An item is considered a leaf if:
      // 1. It has no children
      // 2. All of its siblings also have no children
      const isLeaf = !hasChildren && allSiblingsWithoutChildren;

      // Add the current item to the flattened tree, with a `level` and `isLeaf` property
      const currentItem = { ...item, level, isLeaf };
      _flatTree.push(currentItem);

      // Recursively flatten the tree for children, if any
      if (item.children && item.children.length > 0) {
        _flatTree = _flatTree.concat(flattenTree(item.children, item, level + 1)); // Recursively flatten children
      }
    });

    return _flatTree.length > 0 ? _flatTree : null;
  }, []);

  // Fetch data and build tree structure
  const fetchData = useCallback(
    async (sync) => {
      updating.current = true;

      if (sync) {
        const keys = Object.keys(updates.current);
        for (let i = 0; i < keys.length; i++) {
          const update = updates.current[keys[i]];
          const keyArr = keys[i].split("-");
          const table = keyArr[0];
          const id = parseInt(keyArr[1], 10);

          if (update === "delete") {
            await supabase.from(table).delete().eq("id", id);
          } else {
            await supabase.from(table).update(update).eq("id", id);
          }
        }
      }
      updates.current = {};

      const { data } = await supabase
        .from("goals")
        .select("*, workitems(*)")
        .eq("project_id", user.proj.id)
        .eq("type", "unplanned")
        .order("created_at", { foreignTable: "workitems", ascending: true });

      const rootItems = buildTree(data[0]);
      const newFlatTree = flattenTree(rootItems);
      setFlatTree(newFlatTree);
      setLoading(false);
      updating.current = false;
    },
    [supabase, setFlatTree, buildTree, flattenTree, user]
  );

  const submitItem = useCallback(
    async (e) => {
      let item = localdata.current.workitems.find((_item) => _item.id === editItemRef.current.id);
      item.name = e.target.value;
      await supabase.from("workitems").update({ name: e.target.value }).eq("id", editItemRef.current.id);
    },
    [supabase]
  );

  const submitItemMove = useCallback(
    (activeId) => {
      // First do local change for responsivness
      const rootItems = buildTree(localdata.current);
      const newFlatTree = flattenTree(rootItems);
      const newActiveIndex = newFlatTree.findIndex((item) => item.id === activeId);
      setFlatTree(newFlatTree);
      setActiveIndex(newActiveIndex);

      // Then send and fetch
      fetchData(true);
    },
    [buildTree, flattenTree, setFlatTree, setActiveIndex, fetchData]
  );

  const deselectItems = useCallback(() => {
    itemsRef.current[activeIndex]?.blur();
    setEditItem(null);
    if (!editItem) {
      prevActiveIndex.current = activeIndex > -1 ? activeIndex : prevActiveIndex.current > -1 ? prevActiveIndex.current : 0;
      setActiveIndex(-1);
    }
  }, [itemsRef, setEditItem, editItem, prevActiveIndex, activeIndex, setActiveIndex]);

  const deleteItem = useCallback(async () => {
    if (activeIndex > -1 && !editItem) {
      if (window.confirm("About to delete items.. Are you sure?")) {
        const item = flatTree[activeIndex];
        let parentItem = localdata.current.workitems.find((_item) => _item.id === item.parent_id);

        updates.current["workitems-" + item.id] = "delete";
        if (!item.parent_id) {
          localdata.current.workitems = localdata.current.workitems.filter((_item) => _item.id !== item.id);
          localdata.current.workitems_order = localdata.current.workitems_order.filter((id) => id !== item.id);
          if (!localdata.current.workitems_order) localdata.current.workitems_order = null;
          updates.current["goals-" + localdata.current.id] = { workitems_order: localdata.current.workitems_order };
        } else {
          parentItem.children_order = parentItem.children_order.filter((id) => id !== item.id);
          updates.current["workitems-" + parentItem.id] = { children_order: parentItem.children_order };
        }

        submitItemMove(activeIndex <= 0 ? -1 : flatTree[activeIndex - 1].id);
      }
    }
  }, [localdata, activeIndex, editItem, flatTree, submitItemMove]);

  const addItem = useCallback(
    async (atIndex) => {
      // Add new item to get its new id
      const { data } = await supabase.from("workitems").insert({ name: "", goal_id: localdata.current.id }).select();
      let newItem = data[0];

      if (atIndex > -1) {
        // Another item selected, add right below as same level-sibling
        const selectedSibling = flatTree[atIndex];
        localdata.current.workitems.push(newItem);

        if (!selectedSibling.parent_id) {
          // Root item
          const activeRootIndex = localdata.current.workitems_order.findIndex((id) => id === selectedSibling.id);
          localdata.current.workitems_order.splice(activeRootIndex + 1, 0, newItem.id);
          updates.current["goals-" + localdata.current.id] = { workitems_order: localdata.current.workitems_order };
        } else {
          // Indented item
          newItem.parent_id = selectedSibling.parent_id;
          let parentItem = localdata.current.workitems.find((item) => item.id === newItem.parent_id);
          let insertNewAtIndex = parentItem.children_order.findIndex((id) => id === selectedSibling.id) + 1;
          parentItem.children_order.splice(insertNewAtIndex, 0, newItem.id);
          updates.current["workitems-" + newItem.id] = { parent_id: newItem.parent_id };
          updates.current["workitems-" + parentItem.id] = { children_order: parentItem.children_order };
        }
      } else {
        // No active, add at bottom root level
        if (!localdata.current.workitems_order) localdata.current.workitems_order = [];
        localdata.current.workitems_order.push(newItem.id);
        localdata.current.workitems.push(newItem);
        updates.current["goals-" + localdata.current.id] = { workitems_order: localdata.current.workitems_order };
      }

      setEditItem(newItem);
      submitItemMove(newItem.id);
    },
    [supabase, localdata, flatTree, setEditItem, submitItemMove]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    editItemRef.current = editItem;
  }, [editItem]);

  useEffect(() => {
    if (flatTree && editItem && activeIndex > -1) {
      itemsRef.current[activeIndex]?.focus();
    }
  }, [flatTree, editItem, activeIndex, itemsRef]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useGSAP(
    () => {
      const hasElements = document.getElementsByClassName("workitem").length > 0;
      if (hasElements) {
        if (!loading) {
          tl.current = gsap.timeline().fromTo(
            ".workitem",
            {
              opacity: 0,
              x: -100,
            },
            {
              opacity: 1,
              x: 0,
              ease: "power4.out",
              duration: 0.1,
              stagger: 0.012,
              onComplete: () => (animationDone.current = true),
            }
          );
        }
      } else {
        animationDone.current = true;
      }
    },
    { dependencies: [loading] }
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    <>
      {!loading && (
        <div
          style={{ width: "100%", minHeight: "100vh", display: "inline-block" }}
          onClick={() => {
            deselectItems();
          }}
        >
          <h4 style={{ paddingLeft: 44, paddingTop: 20, color: "#ffffff" }}>Project: {user.proj.name}</h4>
          <FlexLeftCol gap={4} style={{ width: "50%", padding: 40, alignItems: "start" }}>
            {flatTree?.map((item, i) => {
              return (
                <FlexLeftCol key={"item" + item.id} gap={4} style={{ marginLeft: item.level * 40, alignItems: "center", transition: "all 1s easeOut" }}>
                  <WorkItem
                    ref={(ref) => (itemsRef.current[i] = ref)}
                    item={item}
                    itemsById={itemsById.current}
                    text={item.name}
                    active={activeIndex === i}
                    isLeaf={item.isLeaf}
                    hasParent={item.parent_id !== null}
                    animationDone={animationDone.current}
                    isFirst={flatTree.length === 1}
                    onClickCb={() => {
                      setActiveIndex(i);
                      setEditItem(null);
                    }}
                    onChangeCb={submitItem}
                    onDoubleClickCb={() => {
                      setActiveIndex(i);
                      setEditItem(flatTree[i]);
                    }}
                  />
                </FlexLeftCol>
              );
            })}

            {!editItem && (
              <Flex style={{ marginTop: 40 }} gap={10}>
                <KeyboardShortcut type="comboenter" text="New item" large={!flatTree} onClickCb={addItem} />
                {flatTree && <KeyboardShortcut type="tab" text="Indent" />}
              </Flex>
            )}
          </FlexLeftCol>

          {!flatTree && (
            <Flex gap={1} style={{ marginLeft: 100, alignItems: "start" }}>
              <div style={{ marginTop: 20 }}>
                <img alt="arrow" src={arrow} style={{ width: 150, transform: "rotate(20deg) scaleX(-100%)" }} />
              </div>
              <div style={{ marginTop: 80 }}>
                <h2>Let's go!</h2>
                <br />
                Add your first work item, be it a category or simple task.
              </div>
            </Flex>
          )}
        </div>
      )}
    </>
  );
}
