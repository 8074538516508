import styled from "styled-components";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Flex, PADDING } from "styles/global-style";

export const Nav = styled.nav`
  position: relative;
  left: 0px;
  right: 0px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: ${PADDING}px ${PADDING / 1.5}px;
  z-index: 5000;
`;

export const NavLeft = styled.div`
  display: flex;
  column-gap: 8px;
  flex: 1 1 0;
`;

export const NavCenter = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;

  .relative {
    position: relative;
  }
`;

export const NavRight = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;

  .relative {
    position: relative;
  }
`;

export const LogoImage = styled.img`
  width: 150px;
  cursor: pointer;
  z-index: 2;
  vertical-align: middle;
`;

export const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const AuthText = styled.div`
  line-height: 0.8;
`;

export const MenuButton = styled(Menu.Button)`
  margin: -1.5rem;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  text-transform: none;
  border: 0 solid #e5e7eb;
  font-family: inherit;
  cursor: pointer;
`;

export const ProfilePlaceholder = styled.div`
  height: 2.3rem;
  width: 2.3rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #000000;
  background-color: #ffffff;
  border-radius: 9999px;
  text-transform: uppercase;
`;

export const ProfileImage = styled.img`
  height: 2.3rem;
  width: 2.3rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #ffffff;
  background-color: #ffffff;
  border-radius: 9999px;
  text-transform: uppercase;
`;

export const UserName = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .full-name {
    margin-right: 1rem;
    font-size: 16px;
    font-weight: 600;
    color: #111827;
  }

  .job-title {
    margin-right: 1rem;
    font-size: 12px;
    color: #111827;
  }
`;

export const MenuItems = styled.div`
  position: absolute;
  right: 0;
  z-index: 20;
  margin-top: 0.5rem;
  width: 12rem;
  transform-origin: top right;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const MenuItem = ({ children, click, close }) => {
  return (
    <div
      className="popover-menuitem"
      onClick={() => {
        if (click) click();
        if (close) close();
      }}
    >
      {children}
    </div>
  );
};

export const MenuItemButton = styled.button`
  display: block;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  border: none;
  background-image: none;
  text-transform: none;
  font-family: inherit;
  box-sizing: border-box;
`;

export const Separator = ({ noline }) => <div style={{ width: "100%", borderTop: noline ? "1px solid transparent" : "1px solid #d5d5d5", margin: "5px 0px" }} />;

export const IconText = ({ icon, text, active }) => {
  return (
    <Flex gap={1}>
      <div style={{ width: 20, display: "flex" }}>
        <img alt="settings" src={icon} style={{ height: 16, opacity: active ? 1 : 0.4, left: "50%", transform: "translateX(-50%)" }} />
      </div>
      <div style={{ fontWeight: active ? "bold" : "initial", userSelect: "none" }}>{text}</div>
    </Flex>
  );
};
