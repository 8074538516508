import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "utils/AuthContext";
import Button from "components/Button";
import Input from "components/Input";
import { Section, Form } from "styles/global-style";

export default function Password(props) {
  const { user, updatePassword } = useContext(AuthContext);
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const password = useRef(null);
  const confirm = useRef(null);
  const userEmail = useRef(null);

  useEffect(() => {
    const error = hash.split("=")[0].split("#")[1] === "error";
    if (error) {
      setError("Link expired or user not found");
    } else {
      if (user?.email) userEmail.current = user.email;
    }
  }, [hash, user]);

  const validate = useCallback(() => {
    setError(null);
    if (password.current) {
      setInputValid(password.current.value.trim().length && confirm.current.value.trim().length);
    }
  }, [setError, setInputValid, password]);

  const submit = useCallback(() => {
    setIsSubmitting(true);
    if (inputValid) {
      if (password.current.value === confirm.current.value) {
        updatePassword(password.current.value.trim(), (err) => {
          setIsSubmitting(false);
          setError(err);
          if (!err) navigate("/auth");
        });
      } else {
        setIsSubmitting(false);
        setError("Passwords do not match");
      }
    }
  }, [inputValid, navigate, updatePassword]);

  return (
    <Section>
      <h1>Update password</h1>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (inputValid) submit();
        }}
      >
        {userEmail.current && <h5 style={{ alignSelf: "center", fontWeight: 200 }}>{userEmail.current}</h5>}
        <Input
          ref={password}
          placeholder="Password"
          caption="New password"
          onChange={() => {
            validate();
          }}
          password
          disabled={!user && !userEmail.current && !isSubmitting}
          white
          autoFocus
        />
        <Input
          ref={confirm}
          placeholder="Confirm"
          caption="Confirm password"
          onChange={() => {
            validate();
          }}
          password
          disabled={!user && !userEmail.current && !isSubmitting}
          white
        />
        <button type="submit" style={{ display: "none" }} />
        <Button
          text={"Set new password"}
          large
          disabled={!inputValid || isSubmitting}
          callback={() => {
            submit();
          }}
          loading={isSubmitting}
        />
      </Form>

      {error && <div style={{ color: "red", fontWeight: "bold", fontSize: 17 }}>{error}</div>}
    </Section>
  );
}
