import React, { memo, useRef, useState, useCallback } from "react";
import { FlexCenter } from "styles/global-style";
import Button from "components/Button";

import plus from "imgs/plus.png";

export default memo(function Date({ date, showButton, size, addGoalCb, addPersonCb, dateClickCb }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const containerRef = useRef();

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      dateClickCb(!menuOpen);

      const clickListener = (e) => {
        if (!containerRef.current?.contains(e.target)) {
          window.removeEventListener("mouseup", clickListener);
          dateClickCb(false);
          setMenuOpen(false);
        }
      };

      if (!menuOpen) {
        window.addEventListener("mouseup", clickListener);
      } else {
        window.removeEventListener("mouseup", clickListener);
      }
      setMenuOpen(!menuOpen);
    },
    [dateClickCb, setMenuOpen, menuOpen]
  );

  const addGoal = useCallback(() => {
    addGoalCb(date);
    setMenuOpen(false);
  }, [date, addGoalCb, setMenuOpen]);

  const addPerson = useCallback(() => {
    addPersonCb(date);
    setMenuOpen(false);
  }, [date, addPersonCb, setMenuOpen]);

  return (
    <>
      <FlexCenter>
        {showButton ? (
          <div
            style={{
              marginTop: -4,
              background: "#ffffff44",
              borderRadius: "50%",
              height: size,
              width: size,
              cursor: "pointer",
            }}
            onClick={onClickHandler}
          >
            <img alt="dateplus" src={plus} style={{ filter: "brightness(1)", height: "100%" }} />
          </div>
        ) : (
          <div style={{ fontSize: "0.6rem", textAlign: "center", opacity: 0.4 }}>{date.getUTCDate()}</div>
        )}
      </FlexCenter>

      {menuOpen && (
        <div
          ref={containerRef}
          style={{
            position: "absolute",
            top: 18,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "column",
            minWidth: 100,
            zIndex: 100,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "7px 7px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
            <Button text="Add goal" callback={addGoal} lightmode noborder />
            <Button text="Person joins" callback={addPerson} lightmode noborder />
          </div>
          <div
            style={{
              position: "absolute",
              top: -10,
              left: "50%",
              transform: "translateX(-50%)",
              height: 0,
              width: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid white",
            }}
          />
        </div>
      )}
    </>
  );
});
