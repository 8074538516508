import React, { useState, useEffect, useRef } from "react";
import { Flex, FlexSpaceBetweenVert } from "styles/global-style";
import { Draggable } from "@hello-pangea/dnd";

export default function Task({ indexPath, task, clickTaskCb, user }) {
  const [height, setHeight] = useState(50);

  const titleRef = useRef();
  const pillsRef = useRef();

  const SPACE = 6;

  const getStyle = (isDragging, draggableStyle) => ({
    background: "#fff",
    borderRadius: 5,
    padding: "9px 11px",
    margin: 3,
    userSelect: "none",
    width: 130,
    height: Math.max(height, 50),
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.667) 1px 3px 5px",
    color: "black",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  useEffect(() => {
    let _height = 0;
    _height += titleRef.current?.getBoundingClientRect().height;
    _height += pillsRef.current ? pillsRef.current?.getBoundingClientRect().height : 0;
    _height += SPACE;

    setHeight(_height);
  }, [setHeight, titleRef, pillsRef, SPACE]);

  return (
    <Draggable draggableId={indexPath.join("-")} index={indexPath[3]} type="task">
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getStyle(snapshot.isDragging, provided.draggableProps.style)}
          onClick={() => clickTaskCb(task)}
        >
          <FlexSpaceBetweenVert gap={0}>
            <div ref={titleRef} style={{ color: "#000", fontSize: "0.74rem", fontWeight: 500 }}>
              {task.title}
            </div>
            {user && (
              <div ref={pillsRef}>
                <Flex>
                  <Pill value={!user.lastname ? user.firstname : user.firstname + " " + user.lastname.charAt(0)} />
                </Flex>
              </div>
            )}
          </FlexSpaceBetweenVert>
        </div>
      )}
    </Draggable>
  );
}

const Pill = ({ value }) => {
  return (
    <div
      style={{
        fontSize: "0.58rem",
        display: "inline-flex",
        justifyContent: "center",
        background: "#999",
        color: "white",
        border: "1px solid #999",
        borderRadius: 18,
        padding: "2px 6px",
        minWidth: 20,
      }}
    >
      {value}
    </div>
  );
};
