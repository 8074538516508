import styled, { css } from "styled-components";
import sick from "imgs/sick.png";
import plane from "imgs/plane.png";
import kid from "imgs/kid.png";
import check from "imgs/check.png";

export const PADDING = 20;
export const RADIUS = 14;

export const Colors = {
  GREEN: "#42b842",
  ORANGE: "#efa51c",
  PURPLE: "#af1cef",
  GREY: "#aaaaaa",
  TURQUOISE: "#1dbcc2",
  BLUE: "#515be8",
  PINK: "#ef1cda",
  RED: "#cc0000",
};

export const DayStyles = {
  selected: { color: "rgb(91, 192, 222)", icon: check },
  working: { color: "#82c507", icon: null },
  sick: { color: "#ff8c8c", icon: sick },
  vacation: { color: "rgb(241, 194, 50)", icon: plane },
  parental: { color: "rgb(140, 164, 255)", icon: kid },
  holiday: { color: "transparent", border: "0.2rem solid rgb(241, 194, 50)" },
};

export const GoalStyles = {
  large: { color: Colors.ORANGE, fontSize: 17, height: 32, padding: 12 },
  normal: { color: Colors.PURPLE, fontSize: 14, height: 26, padding: 10 },
  small: { color: Colors.GREY, fontSize: 11, height: 20, padding: 8 },
  today: { color: Colors.RED, fontSize: 11, height: 20, padding: 8 },
  massive: { color: Colors.GREY, fontSize: 16, height: 28, padding: 14 },
  unplanned: { color: Colors.GREY + "22", fontSize: 16, height: 28, padding: 14 },
};

export const Container = styled.div`
  max-width: 80rem;
  margin-inline: auto;
  position: relative;
  padding: ${PADDING * 2}px ${PADDING * 2}px;
  border-radius: 30px;
  margin-bottom: ${PADDING}px;
`;

export const Section = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column",
        padding: PADDING * 6 + "px " + PADDING * 2 + "px",
        rowGap: PADDING,
      }}
    >
      {children}
    </div>
  );
};

export const Form = ({ children, width, onSubmit, gap }) => {
  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: gap !== undefined ? gap : PADDING,
        width: width ? width : "initial",
      }}
      onSubmit={(e) => {
        onSubmit(e);
      }}
    >
      {children}
    </form>
  );
};

export const Group = ({ children, caption, white }) => {
  return (
    <div>
      {caption && <div style={{ ...captionStyle, color: white ? "#fff" : "#999" }}>{caption}</div>}
      <div style={{ ...groupStyle, border: white ? "1px solid #ffffff33" : "1px solid #bbb" }}>{children}</div>
    </div>
  );
};

export const SpaceY = styled.div`
  ${({ number }) => `
   & > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(${number} * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(${number} * var(--tw-space-y-reverse));
    }
  `}
`;

export const Title = styled.h2`
  font-weight: 700;
  margin: 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ gap }) => gap || PADDING}px;
`;

export const FlexBottom = styled.div`
  display: flex;
  align-items: end;
  column-gap: ${({ gap }) => gap || PADDING}px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const FlexCenterCol = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const FlexSpaceBetweenVert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const FlexLeftColNoGap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const FlexRightCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: ${({ gap }) => (gap !== undefined ? gap : PADDING)}px;
`;

export const formBase = {
  color: "black",
  background: "#ffffff11",
  backdropFilter: "blur(10px)",
  border: "1px solid #ffffff33",
  padding: "10px 18px",
  borderRadius: 8,
};

export const captionStyle = {
  color: "#bba0ff",
  marginLeft: 7,
  fontSize: 12,
  fontWeight: 700,
};

export const groupStyle = {
  ...formBase,
  background: "transparent",
  padding: `${PADDING}px 18px`,
};

export const inputBase = {
  ...formBase,
  fontSize: 16,
  fontWeight: 500,
  flexGrow: 1,
  height: 30,
  textAlign: "center",
  outline: "none",
};

export const inputStyle = {
  ...inputBase,
};

export const textareaStyle = {
  ...inputBase,
  fontFamily: "inherit",
  textAlign: "left",
  minWidth: 300,
  minHeight: 200,
  padding: "18px 18px",
  fontWeight: 400,
  whiteSpace: "pre-line",
};

export const selectStyle = {
  ...inputBase,
  padding: 0,
  height: 40,
};

export const checkboxStyle = {
  fontWeight: 700,
  fontSize: 13,
  display: "flex",
  alignItems: "center",
  columnGap: PADDING / 2,
};

export const LogoImage = styled.img`
  height: 40px;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    text-align: center;
  }
`;

export const FooterEnd = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 90px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
`;

export const FooterRightIcons = styled.div`
  display: flex;
  justify-content: end;
  @media (max-width: 900px) {
    margin-block: 30px;
    justify-content: center;
  }
`;

export const FooterColumn = styled.div`
  float: left;
  width: 33%;
  @media (max-width: 900px) {
    float: none;
    width: 100%;
    text-align: center;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  background: black;
  height: 14.5rem;
  position: absolute;
  bottom: 0px;
  padding: ${PADDING * 4}px ${PADDING * 2}px;
  box-sizing: border-box;

  @media (max-width: 900px) {
    position: relative;
    height: fit-content;
  }

  ${(props) =>
    props.$innerWidth &&
    css`
      padding-top: ${(props) => (props.$innerWidth < 900 ? "0" : "350px")};
    `}

  ${(props) =>
    props.$sm &&
    css`
      @media (min-width: 640px) {
        max-width: 640px;
      }
    `}

  ${(props) =>
    props.$md &&
    css`
      @media (min-width: 768px) {
        max-width: 768px;
      }
    `}

  ${(props) =>
    props.$lg &&
    css`
      @media (min-width: 1024px) {
        max-width: 1024px;
      }
    `}

  ${(props) =>
    props.$xl &&
    css`
      @media (min-width: 1280px) {
        max-width: 1280px;
      }
    `}

  ${(props) =>
    props["2xl"] &&
    css`
      @media (min-width: 1536px) {
        max-width: 1536px;
      }
    `}
`;
