import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "utils/AuthContext";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import Button from "components/Button";
import { ProfilePlaceholder, LogoImage, MenuItem, Nav, NavLeft, NavLink, NavRight, ProfileImage, IconText, Separator } from "./style";
import { Flex, FlexRightCol, FlexCenterCol } from "styles/global-style";

import logo from "imgs/logo_white.png";
import cards from "imgs/cards.png";
import people from "imgs/people.png";
import cog from "imgs/cog.png";
import signout from "imgs/logout.png";
import gamepad from "imgs/gamepad.png";
import building from "imgs/building.png";
import arrow from "imgs/ellipse-left.png";

export default function AuthMainMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, updateUser, supabase } = useContext(AuthContext);

  const [hideButtons, setHideButtons] = useState(location.pathname.includes("orgproj"));
  const [showOrgs, setShowOrgs] = useState();
  const [showProjs, setShowProjs] = useState();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const setOrg = useCallback(
    async (org) => {
      if (showOrgs || user.memberships.length === 1) {
        if (org.id !== user.org.id) {
          // User switching org, figure out to which project
          let projectId = null;
          const membership = user.memberships.find((mem) => mem.org_id === org.id);
          if (membership?.project_invite !== null) {
            projectId = membership.project_invite;
            await supabase.from("memberships").update({ project_invite: null }).eq("id", membership.id);
          } else {
            const { data } = await supabase.from("projects").select("id").eq("org_id", org.id).order("id", { ascending: false }).limit(1);
            projectId = data[0].id;
          }
          updateUser({ current_org_id: org.id, current_proj_id: projectId });
        }
        setShowOrgs(false);
      } else {
        setShowOrgs(true);
      }
    },
    [showOrgs, setShowOrgs, user, updateUser, supabase]
  );

  const setProject = useCallback(
    (proj) => {
      if (showProjs) {
        if (proj.id !== user.proj.id) {
          setShowProjs(false);
          updateUser({ current_proj_id: proj.id });
        }
      } else {
        setShowProjs(true);
      }
    },
    [setShowProjs, updateUser, user, showProjs]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    setHideButtons(location.pathname.includes("orgproj"));
  }, [location.pathname, setHideButtons]);

  return (
    <Nav>
      <NavLeft>
        {location.pathname.includes("orgproj") && (
          <NavLink to="/">
            <LogoImage alt="logo" src={logo} />
          </NavLink>
        )}
        {!hideButtons && <Button text="People" click="/auth/people" active={location.pathname.includes("people")} noborder image={people} />}
        {!hideButtons && <Button text="Cards" click="/auth/cards" active={location.pathname.includes("cards")} noborder image={cards} />}
        {/*!hideButtons && <Button text="WorkTree" click="/auth/tree" active={location.pathname.includes("tree")} noborder image={branch} imgSize={13} />*/}
      </NavLeft>

      <NavRight gap={8}>
        <Popover className="popover">
          <PopoverButton className="popover-button">
            <Flex gap={10}>
              <FlexRightCol gap={1} style={{ marginTop: 2 }}>
                <div style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: -1 }}>{user?.org?.name}</div>
                <div style={{ fontSize: "0.7rem" }}>{user?.proj?.name}</div>
              </FlexRightCol>
              {user.avatar ? <ProfileImage src={user.avatar} /> : <ProfilePlaceholder>{user?.email.substring(0, 1)}</ProfilePlaceholder>}
            </Flex>
          </PopoverButton>

          {!location.pathname.includes("orgproj") && (
            <PopoverPanel className="popover-panel" transition anchor={{ to: "bottom start", padding: "34px" }}>
              {({ close }) => (
                <>
                  <MenuItem click={() => navigate("/auth/settings/account")} close={close}>
                    {user && user.firstname ? user.firstname : user.email ? user.email : "Account"}
                    {user && user.role ? <div style={{ color: "#aaa", fontSize: 12, marginTop: -8 }}>{user.role}</div> : ""}
                  </MenuItem>

                  <Separator noline />

                  <div style={{ color: "#aaa", fontSize: 12, fontWeight: "bold", margin: "5px 10px", marginBottom: 0 }}>Companies</div>
                  {user.memberships.map((mem, i) => {
                    const currOrg = mem.org_id === user.org.id;
                    if (!showOrgs && !currOrg) return <div key={"org" + i} style={{ visibility: "hidden" }} />;
                    return (
                      <MenuItem key={"org" + i} click={() => setOrg(mem.orgs)}>
                        <Flex>
                          <IconText icon={building} text={mem.orgs.name} active={showOrgs && currOrg} />
                          {currOrg && !showOrgs && user.memberships.length > 1 ? <ExpandIcon /> : <div style={{ width: 4 }} />}
                        </Flex>
                      </MenuItem>
                    );
                  })}

                  <div style={{ color: "#aaa", fontSize: 12, fontWeight: "bold", margin: "10px 10px", marginBottom: 0 }}>Projects</div>
                  {user.projects.map((val, j) => {
                    const currProj = val.id === user.proj.id;
                    return (
                      <MenuItem key={"project" + j} click={() => setProject(val)} close={() => (!currProj || user.projects.length === 1 ? close() : null)}>
                        <IconText icon={gamepad} text={val.name} active={showProjs && currProj} />
                      </MenuItem>
                    );
                  })}

                  <Separator noline />

                  <MenuItem click={() => navigate("/auth/settings/")} close={close}>
                    <IconText icon={cog} text="Settings" />
                  </MenuItem>

                  <MenuItem click={logout} close={close}>
                    <IconText icon={signout} text="Sign out" />
                  </MenuItem>
                </>
              )}
            </PopoverPanel>
          )}
        </Popover>
      </NavRight>
    </Nav>
  );
}

const ExpandIcon = () => {
  return (
    <FlexCenterCol gap={0}>
      <img alt="arrow-up" src={arrow} style={{ width: 4, opacity: 0.3, transform: "rotate(90deg)" }} />
      <img alt="arrow-up" src={arrow} style={{ width: 4, opacity: 0.3, transform: "rotate(-90deg)" }} />
    </FlexCenterCol>
  );
};
