import React, { useRef, useState, useCallback } from "react";
import { Flex, Form, Colors, PADDING, GoalStyles } from "styles/global-style";
import Modal from "components/Modal";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import ColorButton from "components/People/ColorButton";

export default function GoalPopup({ showEditGoal, editingGoal, deleteGoal, submitGoal, closeGoal }) {
  const [inputValid, setInputValid] = useState(false);

  const newTitle = useRef();
  const newBody = useRef();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const validateText = useCallback(() => {
    setInputValid(newTitle.current && newTitle.current.value.trim());
  }, [setInputValid, newTitle]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    showEditGoal && (
      <Modal title={editingGoal?.name ? "Edit Goal" : "New Goal"} closeCb={closeGoal}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitGoal({ name: newTitle.current.value.trim(), body: newBody.current.value.trim(), id: editingGoal.id });
          }}
        >
          <Input ref={newTitle} caption="Title" placeholder="What to achieve here..." onChange={() => validateText()} autoFocus left value={editingGoal?.name} />
          <Flex gap={PADDING / 2} style={{ marginLeft: PADDING / 2 }}>
            {Object.keys(Colors).map((color, i) => {
              if (["RED"].includes(color) || !showEditGoal) return <div key={"color" + i} />;
              const userColor = editingGoal?.color;
              const currColor = userColor ? userColor : GoalStyles[editingGoal.type].color;
              return (
                <ColorButton
                  key={"color" + i}
                  color={Colors[color]}
                  active={Colors[color] === currColor}
                  clickCb={(color) => {
                    editingGoal.color = color;
                    submitGoal({ color: color, id: editingGoal.id }, true);
                  }}
                />
              );
            })}

            <Dropdown
              items={Object.keys(GoalStyles).filter((g) => !["today", "massive", "unplanned"].includes(g))}
              defaultValue={editingGoal?.type}
              changeCb={(e) => {
                submitGoal({ type: e.target.value, id: editingGoal.id }, true);
              }}
            />
          </Flex>
          <Textarea ref={newBody} caption="Description" placeholder="What is the goal about?" onChange={() => validateText()} left value={editingGoal?.body} />
          <Button
            text="Submit"
            callback={() => submitGoal({ name: newTitle.current.value.trim(), body: newBody.current.value.trim(), id: editingGoal.id })}
            large
            color="purple"
            disabled={!inputValid}
          />
        </Form>

        {editingGoal?.id && <Button text="Delete goal" callback={() => deleteGoal(editingGoal.id)} color="red" />}
      </Modal>
    )
  );
}
