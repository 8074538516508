import React, { useState, useCallback, useRef } from "react";
import Button from "components/Button";
import { Flex } from "styles/global-style";

import dots from "imgs/dots.png";
import user from "imgs/user.png";

export default function Row({ height, width, name, notInvited, isMe, canEdit, removePersonCb, showInviteUser }) {
  const [hover, setHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const containerRef = useRef();

  const onClickHandler = useCallback(
    (e) => {
      e.stopPropagation();

      const clickListener = (e) => {
        if (!containerRef.current?.contains(e.target)) {
          window.removeEventListener("mouseup", clickListener);
          setHover(false);
          setMenuOpen(false);
        }
      };

      if (!menuOpen) {
        window.addEventListener("mouseup", clickListener);
      } else {
        window.removeEventListener("mouseup", clickListener);
      }
      setMenuOpen(!menuOpen);
    },
    [setMenuOpen, menuOpen]
  );

  const removePerson = useCallback(() => {
    removePersonCb();
    setMenuOpen(false);
    setHover(false);
  }, [setMenuOpen, setHover, removePersonCb]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        display: "inline-flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
        background: "rgb(170, 170, 170)",
        height: height,
        width: width,
        borderRadius: "0px 3px 3px 0px",
        color: "black",
        fontSize: "0.75rem",
        fontWeight: 500,
        paddingLeft: 8,
        paddingRight: 2,
        whiteSpace: "nowrap",
        cursor: "pointer",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Flex gap={6}>
        {isMe && <img alt="user" src={user} style={{ width: 8 }} />}
        <div>{name}</div>
      </Flex>
      <div style={{ display: "inline-flex", justifyContent: "end", height: "100%" }} onClick={onClickHandler}>
        {hover && canEdit ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "end", height: "100%", width: 26 }}>
            <img alt="dots" src={dots} style={{ width: 12, paddingRight: 7 }} />
          </div>
        ) : (
          <div style={{ width: 26 }} />
        )}
        {notInvited && <div style={{ position: "absolute", top: 3, right: 3, height: 3, width: 3, borderRadius: "50%", background: "red" }} />}
      </div>

      {menuOpen && canEdit && (
        <div
          style={{
            position: "absolute",
            top: 6,
            transform: "translateY(-50%)",
            left: width + 16,
            display: "flex",
            flexDirection: "column",
            minWidth: 100,
            zIndex: 600,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "7px 7px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
            {notInvited && (
              <>
                <Button text="Send invite email" callback={showInviteUser} lightmode noborder />
                <div style={{ position: "absolute", top: 10, right: 10, height: 3, width: 3, borderRadius: "50%", background: "red" }} />
              </>
            )}
            <Button text="Remove person from project" callback={removePerson} lightmode noborder color="red" />
          </div>
          <div
            style={{
              position: "absolute",
              left: -10,
              top: "50%",
              transform: "translateY(-50%)",
              height: 0,
              width: 0,
              borderTop: "10px solid transparent",
              borderBottom: "10px solid transparent",
              borderRight: "10px solid white",
            }}
          />
        </div>
      )}
    </div>
  );
}
