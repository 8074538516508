import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { Flex, FlexCenterCol, Form } from "styles/global-style";
import { AuthContext } from "utils/AuthContext";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

import ninja from "imgs/ninja.gif";

export default function SetOrgAndProj() {
  const navigate = useNavigate();
  const { user, supabase, refresh } = useContext(AuthContext);

  const [inputValid, setInputValid] = useState(false);
  const [showDesciption, setShowDescription] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [orgName, setOrgName] = useState("");

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const newOrgRef = useRef();
  const newProjRef = useRef();
  const newProjDescRef = useRef();
  const orgHasChanged = useRef(false);

  //----------------------------------------------------

  const submit = useCallback(async () => {
    setSubmitted(true);

    const org = await supabase.from("orgs").insert({ name: newOrgRef.current.value }).select();
    const project = await supabase.from("projects").insert({ name: newProjRef.current.value, org_id: org.data[0].id, description: newProjDescRef.current.value }).select();
    await supabase.from("memberships").insert({ person_id: user.id, org_id: org.data[0].id, type: "owner", status: "active" });
    await supabase.from("goals").insert({ project_id: project.data[0].id, type: "unplanned" });
    await supabase
      .from("people")
      .update({ firstname: firstnameRef.current.value, lastname: lastnameRef.current.value, current_org_id: org.data[0].id, current_proj_id: project.data[0].id })
      .eq("id", user.id);
    await supabase.from("days").insert({ person_id: user.id, date: new Date().getNextWeekday().toISOString(), system_type: "joins", project_id: project.data[0].id });

    refresh();
  }, [supabase, refresh, user]);

  const validateInput = useCallback((e) => {
    const _firstname = firstnameRef.current.value.trim();
    const _lastname = lastnameRef.current.value.trim();
    const firstSet = _firstname && _lastname && newOrgRef.current.value.trim() && newProjRef.current.value.trim();
    if (e.target !== newOrgRef.current && !orgHasChanged.current) {
      setOrgName(_firstname ? firstnameRef.current.value.trim() + "'s Org" : "");
    } else {
      orgHasChanged.current = true;
    }

    setShowDescription(firstSet);
    setInputValid(firstSet && newProjDescRef.current?.value.trim());
  }, []);

  //----------------------------------------------------

  useEffect(() => {
    if (user.org && user.proj) navigate("/auth");

    if (user.firstname) {
      newOrgRef.current.value = user.firstname + "'s Org";
      newProjRef.current.focus();
    }
  }, [user, navigate]);

  return (
    <FlexCenterCol>
      <h1>New beginnings!</h1>
      <img alt="ninja" src={ninja} style={{ height: 200 }} />
      <Form width={500}>
        <Flex>
          <Input caption="First name" ref={firstnameRef} onChange={validateInput} disabled={submitted} white autoFocus left value={user?.firstname || ""} />
          <Input caption="Last name" ref={lastnameRef} onChange={validateInput} disabled={submitted} white left value={user?.lastname || ""} />
        </Flex>
        <Input caption="New organization" onChange={validateInput} ref={newOrgRef} disabled={submitted} white value={orgName} left />
        <Input caption="New project" onChange={validateInput} ref={newProjRef} disabled={submitted} white left />
        {showDesciption && <Textarea caption="Short project description" onChange={validateInput} ref={newProjDescRef} white left />}
        <br />
        <br />
        <Button text="Let's GO!" disabled={!inputValid || submitted} callback={submit} large />
      </Form>
      <br />
      <br />
    </FlexCenterCol>
  );
}
