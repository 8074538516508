import React, { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Section } from "styles/global-style";

import withTracker from "utils/withTracker";

function Home() {
  const navigate = useNavigate();
  const { hash } = useLocation();

  useLayoutEffect(() => {
    if (hash) {
      const param = hash.split("=");
      if (param[0] === "#recovery_token") navigate("/recover/" + param[1]);
    }
  }, [hash, navigate]);

  return (
    <Section>
      <h1 style={{ fontSize: 130 }}>Peepz.io</h1>
      <h2>The trinity of team performance</h2>

      <Flex>
        <Pill text="Product" color="purple" />
        <Pill text="Team" color="orange" />
        <Pill text="Tasks" color="limegreen" />
      </Flex>
    </Section>
  );
}

const Pill = ({ text, color }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 140, height: 48, borderRadius: 8, background: color }}>
      <h4>{text}</h4>
    </div>
  );
};

export default withTracker(Home);
