import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "./utils/AuthContext";
import { HelmetProvider } from "react-helmet-async";
import { Container } from "styles/global-style";

// Components
import MainMenu from "./components/MainMenu";
import AuthMainMenu from "./components/Menus/AuthMainMenu";
import AuthSettingsMenu from "components/Menus/AuthSettingsMenu";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Join from "./pages/Join";
import Forgot from "./pages/Forgot";
import Password from "./pages/Password";

// Auth pages
import Cards from "pages/Auth/Cards";
import People from "pages/Auth/People";
import SetOrgAndProj from "pages/Auth/SetOrgAndProj";
import WorkTree from "pages/Auth/WorkTree";

// Auth/Settings
import UserAccount from "./pages/Auth/Settings/Account";
import Organization from "./pages/Auth/Settings/Organization";
import Project from "./pages/Auth/Settings/Project";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route element={<Root />}>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login title="Login" />} />
          <Route path="join" element={<Join title="Join" />} />
          <Route path="forgot" element={<Forgot title="Forgot" />} />
        </Route>
        <Route element={<RootPassword />}>
          <Route path="password" element={<Password />} />
        </Route>
        <Route path="auth" element={<Auth />}>
          <Route index element={<Navigate to="people" replace />} />
          <Route path="orgproj" element={<SetOrgAndProj />} />
          <Route path="cards" element={<Cards />} />
          <Route path="people" element={<People />} />
          <Route path="tree" element={<WorkTree />} />
          <Route path="settings" element={<Settings />}>
            <Route index element={<Navigate to="account" replace />} />
            <Route path="account" element={<UserAccount />} />
            <Route path="org" element={<Organization />} />
            <Route path="proj" element={<Project />} />
            <Route path="password" element={<Password />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HelmetProvider>
  );
}

function Root() {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/auth" />;
  } else {
    return (
      <>
        <MainMenu />
        <div className="container">
          <Outlet />
        </div>
        <Footer />
      </>
    );
  }
}

function RootPassword() {
  return (
    <>
      <MainMenu />
      <div className="container">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

function Auth() {
  const { user, setUserMembership } = useContext(AuthContext);
  let location = useLocation();

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;
  else if ((!user.org || !user.proj) && !location.pathname.includes("orgproj")) return <Navigate to="/auth/orgproj" state={{ from: location }} replace />;

  // If invited, flip status to active person's in org membership
  const currentMembership = user.memberships?.find((mem) => mem.org_id === user.current_org_id);
  if (currentMembership?.status === "invited") setUserMembership("active", currentMembership.id);

  return (
    <>
      <AuthMainMenu />
      <Outlet />
    </>
  );
}

function Settings() {
  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: 200 }}>
          <AuthSettingsMenu />
        </div>
        <div style={{ width: 650 }}>
          <Outlet />
        </div>
      </div>
    </Container>
  );
}

export default App;
