// eslint-disable-next-line no-extend-native
Date.prototype.daystamp = function () {
  const days = this.valueOf() / (1000 * 60 * 60 * 24);
  return Math.floor(days);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days, skipWeekend) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  if (skipWeekend && [0, 6].includes(date.getDay())) date = date.getNextWeekday();
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.subtractDays = function (days, skipWeekend) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  if (skipWeekend && [0, 6].includes(date.getDay())) date = date.getNextWeekday();
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.getISODate = function () {
  var date = new Date(this.valueOf());
  return date.toISOString().split("T")[0];
};

// eslint-disable-next-line no-extend-native
Date.prototype.getMonthName = function (short) {
  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  var shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var value = new Date(this.valueOf());
  return short ? shortMonths[value.getMonth()] : months[value.getMonth()];
};

// eslint-disable-next-line no-extend-native
Date.prototype.getDayName = function (short) {
  var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var shortDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var value = new Date(this.valueOf());
  return short ? shortDays[value.getDay()] : days[value.getDay()];
};

// eslint-disable-next-line no-extend-native
Date.prototype.getNextWeekday = function () {
  var day = new Date(this.valueOf()).getDay();
  if (day === 0) return new Date(this.valueOf()).addDays(1);
  else if (day === 6) return new Date(this.valueOf()).addDays(2);
  else return new Date(this.valueOf());
};

// eslint-disable-next-line no-extend-native
String.prototype.getTextWidth = function (element, fontSize) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const font = getComputedStyle(document.body).font;
  if (!element && fontSize) context.font = "bold " + fontSize + font.slice(font.indexOf("px "));
  else context.font = getComputedStyle(element).font;
  const measure = context.measureText(this.valueOf());
  canvas.remove();
  return measure.width;
};
