import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { AuthContext } from "utils/AuthContext";
import { FlexLeftCol } from "styles/global-style";
import Input from "components/Input";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

export default function Organization() {
  const { supabase, user, refresh, roleAccess } = useContext(AuthContext);
  const navigate = useNavigate();

  const [org, setOrg] = useState(user.org);

  const nameRef = useRef();

  //----------------------------------------------------

  const fetchData = useCallback(async () => {
    const { data } = await supabase.from("orgs").select().eq("id", user.org.id);
    setOrg(data[0]);
  }, [supabase, user]);

  const submit = useCallback(async () => {
    const input = nameRef.current.value.trim();
    if (input) {
      const newName = input ? input : user.firstname + "'s org";
      await supabase.from("orgs").update({ name: newName }).eq("id", user.current_org_id);
    }
    fetchData();
    refresh();
  }, [supabase, fetchData, user.current_org_id, user.firstname, refresh]);

  const deleteOrg = useCallback(async () => {
    if (
      window.confirm(
        "DANGER!\n\nYou are about to delete your whole organization, and all associated projects and data. There is no going back after this.\n\nARE YOU ABSOLUTLEY CERTAIN?"
      )
    ) {
      if (window.confirm("Seriously? Just making sure cause I care..\n\nShould I delete your organization?")) {
        // TODO: Delete all org's uploaded images from Storage
        // const projImgs = await supabase.from("images").select("url").eq("project_id", user.proj.id).eq("type", "project");

        // if (projImgs.data?.length) {
        //   const arr = projImgs.data.map((val) => val.url.split("/").at(-1));
        //   supabase.storage.from("projects").remove(arr);
        // }

        // Start deleting table rows, order important due to db fkeys
        await supabase.from("orgs").delete().eq("id", user.org.id);

        refresh();
        navigate("/auth/orgproj");
      }
    }
  }, [navigate, refresh, supabase, user]);

  //----------------------------------------------------

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (org) nameRef.current.value = org.name;
  }, [org]);

  return (
    <FlexLeftCol>
      <h2>Organization</h2>
      <Input caption="Name" ref={nameRef} onChangeDelay={submit} white left />
      {roleAccess("admin") && (
        <>
          <p style={{ color: "red", marginTop: 80 }}>
            <b>Dangerzone</b>
          </p>
          <Button text="Delete organization" callback={deleteOrg} color="red" />
        </>
      )}
    </FlexLeftCol>
  );
}
