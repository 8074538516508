import { useEffect, useState, forwardRef, useCallback, useRef } from "react";
import { inputStyle, captionStyle } from "styles/global-style";

export default forwardRef(function ComboDropdown(props, ref) {
  const { placeholder, onChange, autoFocus, left, onChangeDelay, onSelect, caption, white, value, list: initialList, nocaption } = props;

  const [textValue, setTextValue] = useState(value ? value : "");
  const [filteredItems, setFilteredItems] = useState(initialList || []);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Tracks highlighted item in dropdown

  const containerRef = useRef();
  const originalPlaceholder = useRef(placeholder);
  const submitTimer = useRef();
  const hasChanged = useRef(false);
  const submittedValue = useRef(value ? value : "");

  //----------------------------------------------------

  const clearPlaceholder = useCallback(() => {
    if (ref?.current === document.activeElement) ref.current.placeholder = "";
  }, [ref]);

  const submit = useCallback(
    (event, notimer) => {
      if (submitTimer.current) clearTimeout(submitTimer.current);
      if (onChange) {
        onChange(event);
        hasChanged.current = false;
      } else if (onChangeDelay) {
        if (!notimer) {
          submitTimer.current = setTimeout(() => {
            onChangeDelay(event);
            hasChanged.current = false;
          }, 6000);
        } else {
          onChangeDelay(event);
          hasChanged.current = false;
        }
      }
    },
    [onChange, onChangeDelay]
  );

  //----------------------------------------------------

  useEffect(() => {
    // AutoFocus doesn't trigger the onFocus
    clearPlaceholder();
  }, [clearPlaceholder]);

  useEffect(() => {
    return () => clearTimeout(submitTimer.current);
  }, [submitTimer]);

  // Filter the list based on user input
  useEffect(() => {
    if (textValue) {
      const filtered = initialList.filter((item) => item.value.toLowerCase().includes(textValue.toLowerCase()));
      setFilteredItems(filtered);
      setHighlightedIndex(filtered.length > 0 && textValue !== submittedValue.current ? 0 : -1); // Automatically highlight the first match
    } else {
      setFilteredItems(initialList);
      setHighlightedIndex(-1); // No highlight if no input
    }
  }, [textValue, initialList]);

  // Handle item selection from dropdown
  const handleSelect = (item) => {
    submittedValue.current = item.value;
    setTextValue(item.value); // Set selected value in input
    setFilteredItems([]); // Clear the dropdown list
    hasChanged.current = false;
    onSelect(item);
    ref?.current?.blur(); // Remove focus from input field
  };

  // Handle keydown events for navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      // Move the highlight down
      setHighlightedIndex((prevIndex) => (prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === "ArrowUp") {
      // Move the highlight up
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1));
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      // Select the highlighted item on Enter key
      e.stopPropagation();
      handleSelect(filteredItems[highlightedIndex]);
    }
  };

  return (
    <div ref={containerRef} style={{ display: "flex", flexDirection: "column", flexGrow: 1, position: "relative" }}>
      {caption && !nocaption && <div style={{ ...captionStyle, color: white ? "#ffffff88" : "#999" }}>{caption}</div>}
      <input
        ref={ref}
        className={white ? "input-white" : "input"}
        placeholder={placeholder}
        style={{
          ...inputStyle,
          textAlign: left ? "left" : "center",
          color: white ? "#fff" : "initial",
          border: white ? "1px solid #ffffff33" : "1px solid #bbb",
        }}
        onChange={(event) => {
          setTextValue(event.target.value);
          hasChanged.current = true;
          submit(event);
        }}
        onFocus={() => {
          clearPlaceholder();
        }}
        onBlur={(event) => {
          setFilteredItems([]);
          if (ref && !ref.current.value.trim()) ref.current.placeholder = originalPlaceholder?.current || "";
          if (hasChanged.current) submit(event, true);
        }}
        autoFocus={autoFocus}
        value={textValue || ""}
        onKeyDown={handleKeyDown} // Add keydown listener
      />

      {/* Dropdown list */}
      {textValue !== submittedValue.current && filteredItems.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: caption && !nocaption ? 68 : 54,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "6px 7px",
            boxSizing: "border-box",
          }}
        >
          {filteredItems.map((item, i) => (
            <ListItem
              key={"item" + i}
              value={item.value}
              id={item.id}
              isHighlighted={i === highlightedIndex} // Highlight current item
              selectCb={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect(item);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
});

const ListItem = ({ value, isHighlighted, selectCb }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        padding: "8px 12px",
        background: isHighlighted || hover ? "#00000011" : "transparent",
        cursor: "pointer",
        color: "black",
        borderRadius: 6,
      }}
      onMouseDown={selectCb}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {value}
    </div>
  );
};
