import React, { useState, useEffect, useRef, useCallback, memo, forwardRef, useImperativeHandle } from "react";
import { Colors } from "styles/global-style";

const WorkItem = ({ text, active, isLeaf, isFirst, animationDone, onClickCb, onDoubleClickCb, onChangeCb }, outerRef) => {
  const [hover, setHover] = useState(false);
  const [textValue, setTextValue] = useState(text);
  const [background, setBackground] = useState("#ffffff13");
  const [color, setColor] = useState("white");
  const [editing, setEditing] = useState(false);
  const [width, setWidth] = useState();

  const containerRef = useRef();
  const innerRef = useRef();
  useImperativeHandle(outerRef, () => innerRef.current, []);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const handleChange = useCallback(
    (e) => {
      setTextValue(e.target.value);
      onChangeCb(e);
    },
    [setTextValue, onChangeCb]
  );

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClickCb(e);
    },
    [onClickCb]
  );

  const clickListener = useCallback(
    (e) => {
      if (!containerRef.current?.contains(e.target)) {
        window.removeEventListener("mousedown", clickListener);
        setEditing(false);
      }
    },
    [containerRef, setEditing]
  );

  const handleDoubleClick = useCallback(
    (e) => {
      e.stopPropagation();
      setEditing(true);
      onDoubleClickCb(e);
      window.addEventListener("mousedown", clickListener);
    },
    [onDoubleClickCb, setEditing, clickListener]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    // innerRef.current.style.width = `${Math.max(textValue.getTextWidth(innerRef.current) + 10, 30)}px`;
    setWidth(Math.max(textValue.getTextWidth(innerRef.current) + 10, 30));
  }, [textValue, innerRef]);

  useEffect(() => {
    if (isLeaf) {
      setBackground("#ffffffee");
      setColor("black");
    } else {
      setBackground("linear-gradient(to right, #ffffff16, transparent)");
      setColor("#ddd");
    }
  }, [isLeaf, setBackground, setColor]);

  useEffect(() => {
    return () => window.removeEventListener("mousedown", clickListener);
  }, [clickListener]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    <>
      <div
        ref={containerRef}
        className="workitem"
        style={{
          display: "inline-flex",
          position: "relative",
          alignItems: "start",
          minWidth: 150,
          outline: active ? (isLeaf ? "3px solid" + Colors.ORANGE : "2px solid #ffffff44") : "1px solid transparent",
          borderRadius: 4,
          padding: "4px 8px",
          cursor: "pointer",
          background: hover ? (isLeaf ? "#fff" : "linear-gradient(to right, #ffffff33, transparent)") : background,
          userSelect: "none",
          height: editing ? 80 : 17,
          width: editing ? 250 : "default",
          transition: "height 100ms ease-out, width 100ms ease-out",
          opacity: animationDone || isFirst ? 1 : 0,
        }}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {/* {hasParent && <div style={{ position: "absolute", left: isLeaf ? -18 : -15, top: "50%", transform: "translateY(-79%)", color: "white", opacity: 0.3 }}>↳</div>} */}
        <input
          ref={innerRef}
          style={{
            background: "transparent",
            outline: "none",
            color: color,
            border: "none",
            fontWeight: isLeaf ? 500 : 700,
            pointerEvents: editing ? "all" : "none",
            width: width,
          }}
          value={textValue}
          onChange={handleChange}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
          placeholder="Item"
        />
        <div style={{ minWidth: 40 }}></div>
      </div>
    </>
  );
};

export default memo(forwardRef(WorkItem));
