import React, { useState, useRef, useEffect, useCallback } from "react";
import { DayStyles } from "styles/global-style";
import Button from "components/Button";

import ellipseleft from "imgs/ellipse-left.png";
import ellipseright from "imgs/ellipse-right.png";

export default function Dot({ size, space_y, disabled, callback, data, row, selected, canShowPopup, isFirstOrLast, canEdit, canLeave, leaveProjectCb, startDragJoinLeaveDayCb }) {
  const [hovering, setHovering] = useState(false);
  const [down, setDown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const popupRef = useRef();
  const showPopupRef = useRef(false);
  const containerRef = useRef();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const onClick = useCallback(
    (e) => {
      if (!canEdit) return;
      e.stopPropagation();
      if (callback) callback(data);
      setShowPopup(!showPopupRef.current);
    },
    [setShowPopup, callback, data, canEdit]
  );

  const handleMouseEnter = (e) => {
    if (!canEdit || disabled) return;
    e.stopPropagation();
    setHovering(true);
  };

  const handleMouseLeave = () => {
    if (!canEdit || disabled) return;
    setDown(false);
    setHovering(false);
  };

  const handleMouseUp = useCallback(
    (e) => {
      if (!canEdit || disabled) return;
      e.preventDefault();
      setDown(false);
    },
    [setDown, disabled, canEdit]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (!canEdit || disabled) return;
      e.preventDefault();
      if (isFirstOrLast) {
        const rect = containerRef.current.getBoundingClientRect();
        const xOffset = e.clientX - rect.left;
        startDragJoinLeaveDayCb(data.day, xOffset);
      }
      setDown(true);
    },
    [containerRef, isFirstOrLast, disabled, startDragJoinLeaveDayCb, setDown, data, canEdit]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    showPopupRef.current = showPopup;
  }, [showPopup, showPopupRef]);

  useEffect(() => {
    if (!selected) setShowPopup(false);
  }, [selected]);

  const color = selected ? DayStyles.selected.color : data.style ? DayStyles[data.style].color : "limegreen";

  return (
    <>
      <div
        ref={containerRef}
        className="dot"
        style={{
          position: "absolute",
          left: data.pos.left,
          top: row * (size + space_y),
          background: color,
          width: size,
          height: size,
          border: DayStyles[data.style]?.border && !selected ? DayStyles[data.style]?.border : "none",
          borderRadius: 3,
          cursor: canEdit ? "pointer" : "initial",
          outline: hovering ? "2px solid white" : "none",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: down ? "scale(0.95)" : "scale(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={onClick}
      >
        {selected && <img alt="selectedIcon" src={DayStyles.selected.icon} style={{ height: "40%", opacity: 0.5 }} />}
      </div>

      {hovering && isFirstOrLast && (
        <div style={{ position: "absolute", left: data.pos.left, top: row * (size + space_y), zIndex: 100 }}>
          <div style={{ position: "absolute", left: -10, top: -2 }}>
            <img alt="ellipse-left" src={ellipseleft} style={{ height: 10, filter: "invert(1)" }} />
          </div>
          <div style={{ position: "absolute", left: 20, top: -2 }}>
            <img alt="ellipse-right" src={ellipseright} style={{ position: "absolute", top: 5, height: 10, filter: "invert(1)" }} />
          </div>
        </div>
      )}

      {showPopup && selected && canShowPopup && canLeave && (
        <div
          ref={popupRef}
          style={{
            position: "absolute",
            left: data.pos.left - 67,
            top: row * size + size * 2,
            display: "flex",
            flexDirection: "column",
            minWidth: 100,
            zIndex: 100,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "8px 8px",
            boxSizing: "border-box",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
            <Button text="Leaves project" noborder lightmode callback={leaveProjectCb} />
          </div>
          <div
            style={{
              position: "absolute",
              top: -10,
              left: "50%",
              transform: "translateX(-50%)",
              height: 0,
              width: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid white",
            }}
          />
        </div>
      )}
    </>
  );
}
