import { useEffect } from "react";

export default function useKey(key, comboKey, callbackDown, callbackUp) {
  useEffect(() => {
    function handleKeydown(e) {
      if (comboKey) {
        if (comboKey === "shift") {
          if (e.shiftKey && e.key === key && callbackDown) {
            callbackDown(e);
          }
        } else {
          if ((e.ctrlKey || e.metaKey) && e.key === key && callbackDown) {
            callbackDown(e);
          }
        }
      } else {
        if (!e.shiftKey && !e.ctrlKey && !e.metaKey && e.key === key && callbackDown) {
          callbackDown(e);
        }
      }
    }
    function handleKeyup(e) {
      if (e.key === key && callbackUp) callbackUp(e);
    }

    if (callbackDown) document.addEventListener("keydown", handleKeydown);
    if (callbackUp) document.addEventListener("keyup", handleKeyup);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [key, callbackDown, callbackUp, comboKey]);
  return;
}
