import React, { useEffect, useState } from "react";
import { Flex, Form } from "styles/global-style";
import Modal from "components/Modal";
import Input from "components/Input";
import ComboDropdown from "components/ComboDropdown";
import Button from "components/Button";

export default function AddPersonPopup({ showAddPerson, closeAddPerson, addPersonDate, list, submitAddPeople }) {
  const [inputValid, setInputValid] = useState(false);
  const [people, setPeople] = useState([{ name: "", email: "", id: null }]); // Initialize with one person including id

  // Update the person's name, email, or id based on index and field type (name, email, id)
  const updatePerson = (index, field, value) => {
    let updatedPeople = [...people];
    updatedPeople[index][field] = value;

    if (field === "name" && people[index].id && people[index].id > -1) {
      updatedPeople[index].id = null;
    }

    // Make sure there's always one empty row available
    const hasEmptySlot = updatedPeople.some((person) => !person.name.trim());
    if (!hasEmptySlot) updatedPeople = [...updatedPeople, { name: "", email: "", id: null }];

    setPeople(updatedPeople);
    // Validate inputs: at least one name must be filled
    const allValid = updatedPeople.some((person) => person.name.trim());
    setInputValid(allValid);
  };

  // Handle user selection from ComboDropdown (including id)
  const handleSelectPerson = (index, selectedItem) => {
    const updatedPeople = [...people];
    updatedPeople[index].name = selectedItem.value;
    updatedPeople[index].id = selectedItem.id; // Store the id of the selected user
    setPeople(updatedPeople);

    // Validate input after selection
    const allValid = updatedPeople.some((person) => person.name.trim());
    setInputValid(allValid);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    const validPeople = people.filter((person) => person.name.trim()); // Filter out empty names
    submitAddPeople(validPeople, addPersonDate); // Pass valid people to the submit handler with ids
  };

  useEffect(() => {
    if (!showAddPerson) {
      setInputValid(false);
      setPeople([{ name: "", email: "", id: null }]);
    }
  }, [showAddPerson]);

  return (
    showAddPerson && (
      <Modal title={"Add person to project"} closeCb={closeAddPerson}>
        <Form onSubmit={handleSubmit} gap={8}>
          <div style={{ color: "#00000088" }}>
            You can just add them now, and email invite later.
            <br />
            Joining on {addPersonDate?.getISODate()}.
          </div>

          {people.map((person, index) => (
            <Flex key={index}>
              <ComboDropdown
                caption="Name"
                list={list} // List contains {id, value}
                placeholder="Name here..."
                value={person.name}
                onChange={(e) => updatePerson(index, "name", e.target.value)} // Handle manual input
                onSelect={(selectedItem) => handleSelectPerson(index, selectedItem)} // Handle selection with id
                autoFocus={index === 0} // Focus only the first input on modal open
                nocaption={index > 0}
                left
              />
              <Input
                caption="Email (optional)"
                placeholder="email@here"
                value={person.email}
                onChange={(e) => updatePerson(index, "email", e.target.value)}
                nocaption={index > 0}
                left
              />
            </Flex>
          ))}
          <br />
          <Button text="Add them" callback={handleSubmit} large color="purple" disabled={!inputValid} />
        </Form>
      </Modal>
    )
  );
}
