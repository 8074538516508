import React from "react";
import { selectStyle } from "styles/global-style";

export default function Dropdown({ items, defaultValue, changeCb }) {
  return (
    <select className="select" style={selectStyle} onChange={changeCb} defaultValue={defaultValue}>
      {items.map((item, i) => {
        return (
          <option key={"item" + i} value={item}>
            {item.charAt(0).toUpperCase() + item.slice(1)}
          </option>
        );
      })}
    </select>
  );
}
