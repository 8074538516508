import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "utils/AuthContext";
import Button from "components/Button";
import Input from "components/Input";
import { Section, Form } from "styles/global-style";
import validator from "validator";

export default function Login(props) {
  const { user, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (user) navigate("/auth");
  }, [user, navigate]);

  const validate = useCallback(() => {
    setError(null);
    if (usernameRef.current && passwordRef.current) {
      const email = usernameRef.current.value.trim();
      if (validator.isEmail(email)) localStorage.setItem("email", email);

      setInputValid(validator.isEmail(usernameRef.current.value.trim()) && passwordRef.current.value.trim().length > 0);
    }
  }, []);

  const submit = useCallback(() => {
    setIsSubmitting(true);
    if (!user && inputValid) {
      login(usernameRef.current.value.trim(), passwordRef.current.value.trim(), (err) => {
        setIsSubmitting(false);
        setError(err);
        if (!err) navigate("/auth");
      });
    }
  }, [user, inputValid, login, navigate]);

  return (
    <Section>
      <h1>Login</h1>
      {!user && (
        <Form
          onSubmit={(e) => {
            if (inputValid) {
              e.preventDefault();
              submit();
            }
          }}
          width={400}
        >
          <Input
            ref={usernameRef}
            placeholder="your@email.here"
            onChange={(e) => {
              setUsername(e.target.value);
              validate();
            }}
            disabled={!isSubmitting}
            value={username}
            white
          />
          <Input
            ref={passwordRef}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
              validate();
            }}
            password
            disabled={!isSubmitting}
            value={password}
            white
          />
          <button type="submit" style={{ display: "none" }} />
          <Button
            text={"Log in"}
            large
            disabled={!inputValid}
            callback={() => {
              submit();
            }}
            loading={isSubmitting}
          />
        </Form>
      )}
      {error && <div style={{ color: "red", fontWeight: "bold", fontSize: 17 }}>Invalid credentials</div>}
      <div
        style={{ marginTop: 80, cursor: "pointer" }}
        onClick={() => {
          navigate("/forgot");
        }}
      >
        Forgot my login
      </div>
    </Section>
  );
}
