import React from "react";

import kitty from "imgs/kitty.png";

export default function Loading() {
  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <img alt="kitty" src={kitty} style={{ width: 80 }} />
    </div>
  );
}
