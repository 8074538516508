import React, { useContext, useRef, useCallback } from "react";
import { Flex, FlexSpaceBetween, FlexLeftCol, SpaceY, Title, FlexLeftColNoGap, PADDING } from "styles/global-style";
import { ImageCropper } from "components/ImageCropper";
import { AuthContext } from "utils/AuthContext";
import Button from "components/Button";
import Input from "components/Input";
import Textarea from "components/Textarea";
import { useNavigate } from "react-router-dom";

export default function Account() {
  const { supabase, user, updateUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const titleRef = useRef();
  const pitchRef = useRef();

  //  ----------------------------------------------------

  const onImageSubmit = useCallback(
    (selectedFile, croppedFile, current) => {
      try {
        // First remove previous image, if any
        if (current) {
          supabase.storage.from("avatars").remove(current.split("/").at(-1));
        }

        // Now upload the new one
        const fileExt = selectedFile.name.split(".").pop();
        const fileName = `${Math.random().toString(16).slice(2)}.${fileExt}`;
        const filePath = `${fileName}`;

        supabase.storage
          .from("avatars")
          .upload(filePath, croppedFile)
          .then(async (res) => {
            if (!res.error) {
              // Add it's info to images table
              const result = current
                ? await supabase.from("images").update({ url: fileName }).eq("ownerid", user.id).eq("type", "avatar")
                : await supabase
                    .from("images")
                    .insert({
                      type: "avatar",
                      url: fileName,
                      ownerid: user.id,
                    })
                    .select("id");

              // Reference the image from the users profile
              if (!current) {
                updateUser({ imageid: result.data[0].id });
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    [supabase, user.id, updateUser]
  );

  const editUser = useCallback(() => {
    updateUser({
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
      title: titleRef.current.value,
      pitch: pitchRef.current.value,
    });
  }, [updateUser]);

  function updatePassword() {
    navigate("/auth/settings/password");
  }

  const deleteAccount = useCallback(async () => {
    if (
      window.confirm(
        "DANGER!\n\nYou are about to delete your user completely, and all associated projects and data. There is no going back after this.\n\nARE YOU ABSOLUTLEY CERTAIN?"
      )
    ) {
      if (window.confirm("Seriously? Just making sure cause I care..\n\nShould I delete your account?")) {
        // Check if user is owner and last remaining of an org
        const { data } = await supabase.from("memberships").select("*, orgs(name)").eq("person_id", user.id);
        const isOwner = data?.find((val) => val.type === "owner");
        const res = await supabase.from("memberships").select().eq("org_id", isOwner.id);

        if (isOwner && res?.data?.length > 1) {
          window.confirm("Cannot delete account, user is an owner of the organization " + isOwner.orgs.name + " which has other users. Try deleting the organization instead.");
        } else {
          // Last and owner, delete the org. Supabase backend deleted the dependency chain
          await supabase.from("orgs").delete().eq("id", isOwner.id);
        }

        // Delete all user's uploaded images from Storage
        const userImgs = await supabase.from("images").select("url").eq("ownerid", user.id);

        if (userImgs.data?.length > 0) {
          const arr = userImgs.data.map((val) => val.url.split("/").at(-1));
          supabase.storage.from("avatars").remove(arr);
        }

        // Start deleting table rows, order important due to db fkeys
        await supabase.from("images").delete().eq("ownerid", user.id);
        await supabase.rpc("delete_user");

        logout();
      }
    }
  }, [logout, supabase, user.id]);

  return (
    <SpaceY number="1.5rem">
      <Title>Account</Title>
      <FlexSpaceBetween>
        <FlexLeftCol style={{ flexGrow: 1 }}>
          <Flex>
            <Input ref={firstnameRef} placeholder="First name" left onChangeDelay={editUser} caption="First name" white value={user.firstname} />
            <Input ref={lastnameRef} placeholder="Last name" left onChangeDelay={editUser} caption="Last name" white value={user.lastname} />
          </Flex>
          <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Input ref={titleRef} placeholder="Title" left onChangeDelay={editUser} caption="Title" white value={user.title} />
          </div>
        </FlexLeftCol>
        <ImageCropper thumb={user.avatar} onFileSubmit={onImageSubmit} hideCardPreview />
      </FlexSpaceBetween>
      <FlexLeftColNoGap>
        <Textarea
          ref={pitchRef}
          onChangeDelay={editUser}
          placeholder="Bli bla bla, bla bla bloo bla. Blaaaooooowww! Bonkibadooo, bibla ba doelido"
          caption="Pitch"
          white
          value={user.pitch}
        />
      </FlexLeftColNoGap>
      <br />
      <br />
      <div>
        <Button text="Update password" callback={updatePassword} />
      </div>
      <br />
      <br />
      {["owner", "admin"].includes(user.role) && (
        <>
          <p style={{ color: "red", paddingBottom: PADDING }}>
            <b>Dangerzone</b>
          </p>
          <Button text="Delete account" callback={deleteAccount} color="red" />
        </>
      )}
    </SpaceY>
  );
}
