import React, { useRef, useState, useCallback } from "react";
import validator from "validator";
import { Form } from "styles/global-style";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";

export default function InviteUserPopup({ showInviteUser, invitingUser, people, submitInviteCb, closeInviteUserCb }) {
  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const emailRef = useRef();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const validateText = useCallback(() => {
    // TODO: check if email already exist in project
    const email = emailRef.current.value.trim();
    const emailTaken = people.map((peep) => peep.person.email).includes(email);
    setInputValid(validator.isEmail(email) && !emailTaken);
    setError(emailTaken ? "Email already used in project" : null);
  }, [setInputValid, emailRef, people]);

  const submitInvite = useCallback(() => {
    setSubmitting(true);
    submitInviteCb(invitingUser.id, emailRef.current.value, invitingUser.firstname + invitingUser.lastname);
    closeInviteUserCb();
  }, [submitInviteCb, closeInviteUserCb, invitingUser, emailRef]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    showInviteUser && (
      <Modal title={invitingUser?.name ? `Invite ${invitingUser.firstname}` : "Invite User"} closeCb={closeInviteUserCb}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitInvite();
          }}
        >
          <Input ref={emailRef} caption="Email" placeholder="Email@here.com" onChange={() => validateText()} autoFocus left />
          {error && <center style={{ color: "red", fontWeight: "bold", fontSize: 17 }}>{error}</center>}
          <Button text="Submit" callback={submitInvite} disabled={!inputValid || submitting} lightmode large />
        </Form>
      </Modal>
    )
  );
}
