import { useEffect, useState, forwardRef, useCallback, useRef, useImperativeHandle } from "react";
import { captionStyle } from "styles/global-style";

import xmark from "imgs/xmark.png";

export default forwardRef(function PillDropdown(props, ref) {
  const { placeholder, onSelect, onClear, selectedIdx, list: initialList, caption, light, pillStyle } = props;

  const [selected, setSelected] = useState(selectedIdx > -1 ? selectedIdx : -1);
  const [textValue, setTextValue] = useState(null);
  const [filteredItems, setFilteredItems] = useState(initialList || []);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Tracks highlighted item in dropdown
  const [hasFocus, setHasFocus] = useState(false);
  const [width, setWidth] = useState(40);

  const containerRef = useRef();
  const inputRef = useRef();
  const originalPlaceholder = useRef(placeholder);
  const hasChanged = useRef(false);
  const hiddenInputRef = useRef();

  //----------------------------------------------------

  useEffect(() => {
    setWidth(hiddenInputRef.current.getBoundingClientRect().width + 15);
  }, [textValue, placeholder]);

  // Filter the list based on user input
  useEffect(() => {
    if (hasFocus) {
      let filteredList;

      if (hasChanged.current) {
        if (textValue) {
          // Filter based on textValue when hasChanged is true
          filteredList = initialList.filter((item) => item.value.toLowerCase().includes(textValue.toLowerCase()));
        } else {
          // Show the full list when textValue is empty
          filteredList = initialList;
        }
        setHighlightedIndex(filteredList.findIndex((item) => item.value.toLowerCase().includes(textValue.toLowerCase())));
      } else if (textValue) {
        // Exclude the selected value and filter based on textValue
        filteredList = initialList.filter((item, idx) => selected !== idx);
      } else {
        // Show the full list if no value is selected
        filteredList = initialList;
      }

      setFilteredItems(filteredList);
    } else {
      setTextValue(selected > -1 ? initialList[selected].value : null);
    }
  }, [textValue, initialList, hasFocus, hasChanged, selected]);

  //----------------------------------------------------

  // Handle item selection from dropdown
  const handleSelect = useCallback(
    (item) => {
      setSelected(initialList.findIndex((val) => val.value === item.value && val.id === item.id));
      setTextValue(item.value.trim()); // Set selected value in input
      hasChanged.current = false;
      if (onSelect) onSelect(item);
      inputRef.current.blur(); // Remove focus from input field
    },
    [setSelected, initialList, setTextValue, hasChanged, onSelect, inputRef]
  );

  const removeSelected = () => {
    setSelected(-1);
    setTextValue(null);
    if (onClear) onClear();
  };

  // Handle keydown events for navigation
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        // Move the highlight down
        setHighlightedIndex((prevIndex) => (prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0));
      } else if (e.key === "ArrowUp") {
        // Move the highlight up
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1));
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        // Select the highlighted item on Enter key
        e.stopPropagation();
        handleSelect(filteredItems[highlightedIndex]);
      } else if (e.key === "Escape") {
        e.stopPropagation();
        inputRef.current.blur();
      }
    },
    [filteredItems, handleSelect, highlightedIndex, inputRef]
  );

  useImperativeHandle(
    ref,
    () => ({
      getSelectedId: () => {
        return initialList[selected].id;
      },
      focus: () => {
        inputRef.current.focus();
      },
    }),
    [initialList, selected, inputRef]
  );

  return (
    <div ref={containerRef} style={{ position: "relative", display: "flex", alignItems: "center" }}>
      {caption && <div style={{ ...captionStyle, color: light ? "#ffffff88" : "#999" }}>{caption}</div>}
      <input
        ref={inputRef}
        className={light ? "input-white" : "input"}
        placeholder={placeholder}
        style={{
          ...pillStyle,
          fontSize: 10,
          padding: "2px 4px",
          textAlign: "center",
          border: light ? "1px solid #ffffff33" : "1px solid #bbb",
          outline: "none",
          width: width,
          minWidth: 20,
          cursor: hasFocus ? "initial" : "pointer",
          paddingRight: hasFocus && selected > -1 ? 10 : 4,
          filter: selected > -1 ? "none" : "grayscale(100%)",
          opacity: selected > -1 ? 1 : 0.7,
        }}
        onChange={(event) => {
          setTextValue(event.target.value);
          hasChanged.current = true;
        }}
        onFocus={() => {
          inputRef.current.placeholder = "";
          hasChanged.current = false;
          setHasFocus(true);
        }}
        onBlur={(event) => {
          if (inputRef && !inputRef.current.value.trim()) inputRef.current.placeholder = originalPlaceholder?.current || "";
          setHasFocus(false);
          hasChanged.current = false;
        }}
        value={textValue || ""}
        onKeyDown={handleKeyDown} // Add keydown listener
      />
      {hasFocus && selected > -1 && (
        <img
          alt="X"
          src={xmark}
          style={{ position: "absolute", height: 7, width: 7, right: 6, top: 5, filter: "invert(1)", cursor: "pointer", zIndex: 100 }}
          onMouseDown={() => {
            removeSelected();
          }}
        />
      )}

      {/* Dropdown list */}
      {hasFocus && filteredItems.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: caption ? 30 : 20,
            display: "flex",
            flexDirection: "column",
            zIndex: 100,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            marginTop: "2px",
            borderRadius: 8,
            padding: "3px 4px",
            boxSizing: "border-box",
          }}
        >
          {filteredItems.map((item, i) => (
            <ListItem
              key={"item" + i}
              value={item.value}
              id={item.id}
              isHighlighted={i === highlightedIndex} // Highlight current item
              selectCb={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect(item);
              }}
            />
          ))}
        </div>
      )}

      {/* Hidden field just for measuring width */}
      <span
        ref={hiddenInputRef}
        style={{
          display: "inline-block",
          position: "absolute",
          fontSize: 11,
          visibility: "hidden",
        }}
      >
        {textValue ? textValue : placeholder}
      </span>
    </div>
  );
});

const ListItem = ({ value, isHighlighted, selectCb }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      style={{
        padding: "4px 12px",
        background: isHighlighted || hover ? "#00000011" : "transparent",
        cursor: "pointer",
        color: "black",
        borderRadius: 4,
        whiteSpace: "nowrap",
        fontSize: 13,
      }}
      onMouseDown={selectCb}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {value}
    </div>
  );
};
