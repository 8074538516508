import React, { useState } from "react";

import arrow from "imgs/arrow-right.png";

const SIZE = 30;

export default function CircleArrow({ onClickCb, inverted }) {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        background: hover ? "#ffffff44" : "#ffffff22",
        transform: hover ? "scale(1.1)" : "scale(1)",
        width: SIZE,
        height: SIZE,
        cursor: "pointer",
        userSelect: "none",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        if (onClickCb) onClickCb(e);
      }}
    >
      <img alt="arrow" src={arrow} style={{ width: 14, filter: "invert(1)", transform: inverted ? "none" : "rotate(180deg)" }} />
    </div>
  );
}
