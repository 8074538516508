import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "utils/AuthContext";
import Input from "components/Input";
import Button from "components/Button";
import { Section, Form } from "styles/global-style";
import validator from "validator";

export default function Join(props) {
  const { user, join } = useContext(AuthContext);
  const navigate = useNavigate();

  const [inputValid, setInputValid] = useState(false);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const username = useRef(null);
  const password = useRef(null);

  useEffect(() => {
    if (user) navigate("/auth");
  }, [user, navigate]);

  const validate = useCallback(() => {
    setError(null);
    if (username.current && password.current) {
      setInputValid(validator.isEmail(username.current.value.trim()) && password.current.value.trim().length > 0);
    }
  }, []);

  const submit = useCallback(async () => {
    setSubmitting(true);
    join(username.current.value.trim(), password.current.value.trim(), (err) => {
      setError(err.message);
      setSubmitting(false);
    });
  }, [join, setSubmitting]);

  return (
    <Section>
      <h1>Join the network</h1>

      {!user && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
          width={400}
        >
          <Input
            ref={username}
            placeholder="your@email.here"
            onChange={() => {
              validate();
            }}
            white
          />
          <Input
            ref={password}
            placeholder="Password"
            onChange={() => {
              validate();
            }}
            password
            white
          />
          <button type="submit" style={{ display: "none" }} />
          <Button
            text={"Join network"}
            large
            disabled={!inputValid}
            loading={submitting}
            callback={() => {
              submit();
            }}
          />
        </Form>
      )}
      {error && <div style={{ color: "red", fontWeight: "bold", fontSize: 17 }}>Invalid credentials</div>}
    </Section>
  );
}
