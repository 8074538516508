import { useEffect, useState, forwardRef, useCallback, useRef } from "react";
import { inputStyle, captionStyle } from "styles/global-style";

export default forwardRef(function Input(props, ref) {
  const { placeholder, onChange, autoFocus, password, left, onChangeDelay, caption, white, value, nocaption, noborder, customStyle, onFocusCb } = props;

  const [textValue, setTextValue] = useState(value ? value : "");

  const originalPlaceholder = useRef(placeholder);
  const submitTimer = useRef();
  const hasChanged = useRef(false);
  const style = useRef();

  //----------------------------------------------------

  const clearPlaceholder = useCallback(() => {
    if (ref?.current === document.activeElement) ref.current.placeholder = "";
  }, [ref]);

  const submit = useCallback(
    (event, notimer) => {
      if (submitTimer.current) clearTimeout(submitTimer.current);
      if (onChange) {
        onChange(event);
        hasChanged.current = false;
      } else if (onChangeDelay) {
        if (!notimer) {
          submitTimer.current = setTimeout(() => {
            onChangeDelay(event);
            hasChanged.current = false;
          }, 6000);
        } else {
          // On blur or leaving page
          onChangeDelay(event);
          hasChanged.current = false;
        }
      }
    },
    [onChange, onChangeDelay]
  );

  //----------------------------------------------------

  useEffect(() => {
    // AutoFocus doesn't trigger the onFocus
    clearPlaceholder();
  }, [clearPlaceholder]);

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  useEffect(() => {
    return () => clearTimeout(submitTimer.current);
  }, []);

  style.current = customStyle ? customStyle : inputStyle;

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      {caption && !nocaption && <div style={{ ...captionStyle, color: white ? "#ffffff88" : "#999" }}>{caption}</div>}
      <input
        ref={ref}
        className={white ? "input-white" : "input"}
        placeholder={placeholder}
        style={{
          ...style.current,
          textAlign: left ? "left" : "center",
          color: white ? "#fff" : "initial",
          border: noborder ? "none" : white ? "1px solid #ffffff33" : "1px solid #bbb",
        }}
        onChange={(event) => {
          setTextValue(event.target.value);
          hasChanged.current = true;
          submit(event);
        }}
        onFocus={() => {
          clearPlaceholder();
          if (onFocusCb) onFocusCb();
        }}
        onBlur={(event) => {
          if (ref && !ref.current.value.trim()) ref.current.placeholder = originalPlaceholder?.current || "";
          if (hasChanged.current) submit(event, true);
        }}
        autoFocus={autoFocus}
        type={password ? "password" : ""}
        value={textValue || ""}
        maxLength={50}
      />
    </div>
  );
});
