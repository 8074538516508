import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { AuthContext } from "utils/AuthContext";
import { Flex, FlexLeftCol, FlexBottom, FlexCenter } from "styles/global-style";
import CircleArrow from "components/CircleArrow";
import DaySelect from "components/People/DaySelect";
import DatesGoals from "components/People/DatesGoals";
import Row from "components/People/Row";
import Dot from "components/People/Dot";
import TodayButton from "components/People/TodayButton";
import GoalPopup from "components/People/GoalPopup";
import AddPersonPopup from "components/People/AddPersonPopup";
import InviteUserPopup from "components/People/InviteUserPopup";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import useKey from "utils/KeyListener";

import arrow from "imgs/arrow.png";

const clone = require("rfdc")();

const GRID_SPACE_Y = 2;
const GRID_SPACE_X = 2;
const GRID_SPACE_WEEKEND = 6;
const BOX_SIZE = 16;
const FONT_WIDTH = 10;
const MAX_NAME = 16;

export default function People() {
  const { user, supabase, roleAccess } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [people, setPeople] = useState(); // List of people & data to display
  const [showDaySelect, setShowDaySelect] = useState(false);
  const [showEditGoal, setShowEditGoal] = useState(false);
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [todayInRange, setTodayInRange] = useState(true);
  const [hideDeparted, setHideDeparted] = useState(!user.proj.settings.find((s) => s.name === "departedHidden"));

  const containerRef = useRef(); //Grid container div
  const peopleAllRawData = useRef(); //DB fetch storage
  const peopleAllFormatted = useRef(); //DB fetch storage but formatted
  const goals = useRef(); //DB fetch storage
  const longestName = useRef(10);
  const origDate = useRef(new Date().subtractDays(10, true));
  const startDate = useRef(new Date().subtractDays(10, true));
  const dates = useRef();
  const selectedDays = useRef();
  const editingGoal = useRef();
  const invitingUser = useRef();
  const movingDay = useRef();
  const showWeekends = useRef();
  const tl = useRef();
  const addPersonDate = useRef();
  const dotsContainerRef = useRef();
  const animationFrameId = useRef();
  const xIndexRef = useRef();
  const targetMovedJoinLeaveDay = useRef();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useKey("ArrowRight", false, () => {
    buildGrid(startDate.current.addDays(30, true));
  });

  useKey("ArrowLeft", false, () => {
    buildGrid(startDate.current.subtractDays(30, true));
  });

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const buildGrid = useCallback(
    (_startDate) => {
      const gridWidth = containerRef.current?.getBoundingClientRect().width - longestName.current * FONT_WIDTH || 800;
      startDate.current = _startDate instanceof Date ? _startDate : origDate.current;

      let date = startDate.current;
      let gridPositionsX = [];
      let x = 0;

      // Generate grid positions for the dates
      while (x < gridWidth) {
        const slot = { left: x, right: x + BOX_SIZE, date: date };
        gridPositionsX.push(slot);
        x += BOX_SIZE + GRID_SPACE_X;
        date = date.addDays(1);
        if (showWeekends.current) {
          if ([1, 6].includes(date.getDay())) x += GRID_SPACE_WEEKEND; // Add extra space for weekends
        } else if (date.getDay() === 6) {
          x += GRID_SPACE_WEEKEND;
          date = date.addDays(2); // Skip to Monday if weekends are not shown
        }
      }

      let _people = [];

      // Process each person and their available days
      for (let i = 0; i < peopleAllRawData.current.length; i++) {
        const person = peopleAllRawData.current[i];

        // Extract and sort join and leave days
        const joinLeaveDays = person.days
          .filter((day) => day.system_type === "joins" || (day.system_type === "leaves" && day.project_id === user.proj.id))
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        let _activeRanges = []; // Ranges of dates where person is active on project
        let currentJoinDate = null;

        // Create ranges of active dates based on joins and leaves
        joinLeaveDays.forEach((day) => {
          if (day.system_type === "joins") {
            currentJoinDate = new Date(day.date);
          } else if (day.system_type === "leaves" && currentJoinDate) {
            _activeRanges.push([currentJoinDate, new Date(day.date).addDays(1, true)]);
            currentJoinDate = null;
          }
        });

        // If there's a trailing joins date without a leaves date, consider the range as perpetually active
        if (currentJoinDate) {
          _activeRanges.push([currentJoinDate, null]); // null indicates perpetual activity
        }

        _people[i] = {
          person: person,
          days: [],
          activeRanges: _activeRanges,
        };

        // Map over the grid positions to add days for each person
        let prevActive = false;
        for (let j = 0; j < gridPositionsX.length; j++) {
          const currentDay = gridPositionsX[j].date;

          // Check if the current day is within any active range
          const isActive = _activeRanges.some(
            ([joinDate, leaveDate]) => currentDay >= joinDate && (leaveDate === null || currentDay <= leaveDate) // null leaveDate means perpetual
          );

          // Find the status of the current day (working, etc.)
          const dayStatus = person.days.find((storedDay) => storedDay.date === currentDay.toISOString().split("T")[0]);

          _people[i].days.push({
            pos: gridPositionsX[j],
            day: currentDay,
            day_type: dayStatus?.day_type,
            system_type: dayStatus?.system_type,
            daystamp: currentDay.daystamp(),
            id: dayStatus?.id,
            style: dayStatus?.day_type?.style || "working",
            personIndex: i,
            dayIndex: j,
            isActive, // Determine if the day should be rendered
            isFirstOrLast: prevActive !== isActive, // Is a joins or leaves date, enabling user to move date
          });

          // Since active is looking backwards, on a leaves day we need to go back a step to fix
          if (prevActive && !isActive) {
            _people[i].days[j - 1].isFirstOrLast = true;
          }

          prevActive = isActive;
        }
      }

      const today = new Date().toISOString().split("T")[0];
      const _todayInRange = gridPositionsX[0].date.getISODate() <= today && today <= gridPositionsX[gridPositionsX.length - 1].date.getISODate();
      setTodayInRange(_todayInRange);
      dates.current = gridPositionsX;
      peopleAllFormatted.current = _people;

      // APPLY ANY DISPLAY FILTER to people list xxxxxxxxxxxxxxxxxxxxxxxxxxx
      let _peopleFiltered = clone(_people);
      _peopleFiltered = _peopleFiltered.filter((member) => {
        const lastActiveRange = member.activeRanges ? member.activeRanges.slice(-1) : null;
        const neverLeaves = lastActiveRange[0][1] === null;
        return neverLeaves || lastActiveRange[0][1] > startDate.current || !hideDeparted;
      });

      setPeople(_peopleFiltered);
    },
    [peopleAllRawData, setPeople, dates, containerRef, origDate, startDate, user.proj.id, hideDeparted]
  );

  const fetchData = useCallback(
    async (_startDate) => {
      // Get users with membership in this org
      const res1 = await supabase
        .from("memberships")
        .select("*, people(*, days(*, day_type(*)))")
        .eq("org_id", user.org.id)
        .order("date", { foreignTable: "people.days", ascending: true });
      let res2 = await supabase.from("goals").select().eq("project_id", user.proj.id).not("date", "is", null).order("date");

      let _peopleAllRawData = res1.data.map((member) => ({
        ...member.people,
        status: member.status,
        type: member.type,
        days: member.people.days.filter((day) => day.project_id === user.proj.id || day.day_type !== null), // Remove other projects days but allow all sick/vaca/etc days
      }));

      longestName.current = Math.min(
        _peopleAllRawData?.reduce((acc, curr) => {
          const nameOrEmail = curr.firstname.trim() ? curr.firstname + " " + curr.lastname : curr.email;
          return acc < nameOrEmail.length ? nameOrEmail.length : acc;
        }, 0),
        MAX_NAME
      );
      peopleAllRawData.current = _peopleAllRawData;
      goals.current = res2.data;

      // Add today marker
      const todayObj = { id: 0, name: "TODAY", date: new Date().toISOString().split("T")[0], type: "today", disabled: true };
      const todayIndex = goals.current.findIndex((v) => new Date() <= new Date(v.date));
      if (todayIndex > -1) goals.current.splice(todayIndex, 0, todayObj);
      else goals.current.push(todayObj);

      buildGrid(_startDate);
    },
    [longestName, peopleAllRawData, buildGrid, supabase, user]
  );

  const selectDay = useCallback(
    (dayData) => {
      if (selectedDays.current?.constructor !== Array) selectedDays.current = [];

      const alreadySelected = selectedDays.current.findIndex((day) => day.row === dayData.personIndex && day.col === dayData.dayIndex);
      if (alreadySelected > -1) {
        // Day is already selected, remove it from selection
        selectedDays.current.splice(alreadySelected, 1);
      } else {
        // Add selected day
        selectedDays.current.push({ row: dayData.personIndex, col: dayData.dayIndex });
      }
      setShowDaySelect(selectedDays.current.length);
    },
    [selectedDays, setShowDaySelect]
  );

  const closeDaySelect = useCallback(() => {
    selectedDays.current = null;
    setShowDaySelect(false);
  }, [setShowDaySelect, selectedDays]);

  const submitDays = useCallback(
    async (typeId, typeName) => {
      let toDelete = [];
      let toUpsert = [];
      let toInsert = [];
      for (var i = 0; i < selectedDays.current.length; i++) {
        const dayData = selectedDays.current[i];
        const day = people[dayData.row].days[dayData.col];
        var target = {
          person_id: people[dayData.row].person.id,
          date: day.day.toISOString(),
          day_type: typeId,
        };

        if (day.id && day.day_type) {
          //Previous record exist in db
          if (typeName === "working") toDelete.push(day.id);
          else if (day.style !== typeName) toUpsert.push({ ...target, id: day.id }); //Change type for record
        } else {
          if (typeName !== "working") toInsert.push(target); //Insert new day status record
        }
      }

      if (toDelete.length) await supabase.from("days").delete().in("id", toDelete);
      if (toUpsert.length) await supabase.from("days").upsert(toUpsert);
      if (toInsert.length) await supabase.from("days").insert(toInsert);

      closeDaySelect();
      if (toUpsert.length || toDelete.length || toInsert.length) fetchData(startDate.current);
    },
    [people, selectedDays, closeDaySelect, fetchData, supabase]
  );

  const submitGoal = useCallback(
    async (data, keepOpen) => {
      if (data) {
        await supabase.from("goals").update(data).eq("id", data.id);
      } else if (!editingGoal.current.id) {
        const res = await supabase
          .from("goals")
          .insert({
            date: editingGoal.current.date.toISOString(),
            project_id: user.proj.id,
            type: "normal",
          })
          .select();
        editingGoal.current = res.data[0];
        setShowEditGoal(true);
      }

      if (!keepOpen) {
        editingGoal.current = null;
        setShowEditGoal(false);
      }
      fetchData(startDate.current);
    },
    [editingGoal, user, fetchData, supabase]
  );

  const deleteGoal = useCallback(
    async (id) => {
      // If Goal has Stories (work) attached to it in Cards, deny user Goal deletion
      const { data } = await supabase.from("stories").select().eq("goal", id);

      if (data.length > 0) {
        const goal = goals.current.find((_goal) => _goal.id === id);
        window.alert("The goal " + goal.name + " contains Stories in it, found under Cards.\n\nPlease delete those first if you wish to remove this goal.");
      } else {
        if (id) await supabase.from("goals").delete().eq("id", id);
      }

      if (data.length <= 0) {
        editingGoal.current = null;
        setShowEditGoal(false);
        fetchData(startDate.current);
      }
    },
    [supabase, fetchData, editingGoal, setShowEditGoal]
  );

  const closeGoal = useCallback(() => {
    editingGoal.current = null;
    setShowEditGoal(false);
  }, [setShowEditGoal, editingGoal]);

  const closeAddPerson = useCallback(() => {
    addPersonDate.current = null;
    setShowAddPerson(false);
  }, [setShowAddPerson, addPersonDate]);

  const getDayBoundries = useCallback(
    (personId, date) => {
      const person = peopleAllRawData.current.find((peep) => peep.id === personId);
      const daysFiltered = person.days.filter((day) => !day.day_type && day.project_id === user.proj.id);
      let dayIdx = daysFiltered.findIndex((d) => d.date === date.getISODate() && !d.day_type); // No day type means joins or leaves
      let day = daysFiltered[dayIdx];

      let leftBoundryIdx = -1;
      let leftBoundryDay = null;
      let rightBoundryIdx = -1;
      let rightBoundryDay = null;
      if (!day) {
        // date is arbritrary, not existing in db

        // search left from arbritrary date
        for (let i = daysFiltered.length - 1; i >= 0; i--) {
          if (new Date(daysFiltered[i].date) < date) {
            leftBoundryIdx = dates.current.findIndex((d) => d.date.getISODate() === daysFiltered[i].date);
            leftBoundryDay = daysFiltered[i];
            break;
          }
        }
        // search right from arbritrary date
        for (let j = 0; j < daysFiltered.length; j++) {
          if (new Date(daysFiltered[j].date) > date) {
            rightBoundryIdx = dates.current.findIndex((d) => d.date.getISODate() === daysFiltered[j].date);
            rightBoundryDay = daysFiltered[j];
            break;
          }
        }
      } else {
        // date exists in db
        leftBoundryIdx = dayIdx > 0 ? dates.current.findIndex((d) => d.date.getISODate() === daysFiltered[dayIdx - 1].date) : -1;
        rightBoundryIdx = dayIdx < daysFiltered.length - 1 ? dates.current.findIndex((d) => d.date.getISODate() === daysFiltered[dayIdx + 1].date) : -1;
        leftBoundryDay = daysFiltered[dayIdx - 1];
        rightBoundryDay = daysFiltered[dayIdx + 1];
      }
      return {
        leftBoundry: leftBoundryIdx > -1 ? { index: leftBoundryIdx, day: leftBoundryDay } : null,
        rightBoundry: rightBoundryIdx > -1 ? { index: rightBoundryIdx, day: rightBoundryDay } : null,
        day,
      };
    },
    [peopleAllRawData, dates, user.proj.id]
  );

  const submitAddPeople = useCallback(
    async (newPeople, date) => {
      // Filter away suggested people that are already active on that date in the project
      const newPeopleFiltered = newPeople.filter((newPerson) => {
        if (!newPerson.id) return true;
        const newPersonFull = peopleAllFormatted.current.find((peep) => peep.person.id === newPerson.id);
        return !newPersonFull.activeRanges.some((range) => (!range[1] && date > range[0]) || (range[1] && date > range[0] && date < range[1]));
      });

      // Execute invite/add to project
      for (var i = 0; i < newPeopleFiltered.length; i++) {
        const newPerson = newPeopleFiltered[i];
        const newPersonExist = peopleAllFormatted.current.find((peep) => peep.person.id === newPerson.id); // Check if person exist in this org

        if (!newPersonExist) {
          // Person new to org
          let personId = null;
          let status = null;

          if (newPerson.email) {
            // Email included, send direct invite
            let redirectTo = "";

            // Check if email already exists in other orgs
            const { data } = await supabase.from("people").select().eq("email", newPerson.email);

            if (data?.length) {
              // User does exist in other org(s)
              if (!data[0]?.current_org_id) {
                // ..though has never logged in (likely status "placeholder")
                await supabase.from("people").update({ current_org_id: user.org.id, current_proj_id: user.proj.id }).eq("email", newPerson.email);
                redirectTo = "password";
              }
              personId = data[0].id;
            } else {
              // User is completely new to Peepz
              const userRes = await supabase.from("people").insert({ firstname: newPerson.name, current_org_id: user.org.id, current_proj_id: user.proj.id }).select();
              personId = userRes.data[0].id;
              redirectTo = "password";
            }

            const res = await fetch("/.netlify/functions/inviteuser", {
              method: "POST",
              body: JSON.stringify({ email: newPerson.email, id: personId, name: newPerson.name, redirectTo: redirectTo, inviter: user.firstname + " " + user.lastname }),
            });
            // TODO: if error 422 - user already exists - make sure that user still gets invite email, but sent explicitely since error makes email sending fail
            console.log(res);
            status = "invited";
          } else {
            // No email, create placeholder for person to invite them later
            const { data } = await supabase.from("people").insert({ firstname: newPerson.name }).select();
            personId = data[0].id;
            status = "placeholder";
          }

          await supabase.from("memberships").insert({ person_id: personId, org_id: user.org.id, type: "member", status: status, project_invite: user.proj.id });
          await supabase.from("days").insert({ person_id: personId, date: date.toISOString(), system_type: "joins", project_id: user.proj.id });
        } else {
          // Person exists in org
          // If joining with a join to the right, pull that ont in instead
          const dayBounds = getDayBoundries(newPersonExist.person.id, date);
          if (dayBounds.rightBoundry?.day.system_type === "joins") {
            await supabase.from("days").update({ date: date.toISOString() }).eq("id", dayBounds.rightBoundry.day.id);
          } else {
            await supabase.from("days").insert({ person_id: newPersonExist.person.id, date: date.toISOString(), system_type: "joins", project_id: user.proj.id });
          }
        }
      }
      closeAddPerson();
      fetchData(startDate.current);
    },
    [peopleAllFormatted, closeAddPerson, supabase, user, getDayBoundries, fetchData]
  );

  const personLeaveProject = useCallback(
    async (personId, date) => {
      // Check for any pre-existing "leaves" day to the right of date
      const person = peopleAllRawData.current.find((peep) => peep.id === personId);
      const daysFiltered = person.days.filter((d) => d.system_type === "leaves" && new Date(d.date) > date.day && d.project_id === user.proj.id);
      const existingLeaveDay = daysFiltered.length ? daysFiltered[0] : null;

      if (existingLeaveDay) {
        await supabase.from("days").update({ date: date.day.toISOString() }).eq("id", existingLeaveDay.id);
      } else {
        await supabase.from("days").insert({ person_id: personId, date: date.day.toISOString(), system_type: "leaves", project_id: user.proj.id });
      }
      closeDaySelect();
      fetchData(startDate.current);
    },
    [peopleAllRawData, supabase, closeDaySelect, fetchData, user.proj.id]
  );

  const handleMoveJoinLeaveDay = useCallback(
    (e) => {
      if (!movingDay.current) return;
      // We use requestAnimationFrame to throttle updates
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      animationFrameId.current = requestAnimationFrame(() => {
        if (movingDay.current) {
          const containerLeft = dotsContainerRef.current.getBoundingClientRect().left;
          const xPosition = e.clientX - containerLeft - movingDay.current.xOffset + BOX_SIZE / 2;

          // Calculate which div the mouse is over
          let newIndex = -1;
          for (let i = 0; i < dates.current.length; i++) {
            const d = dates.current[i];
            if (d.left < xPosition && xPosition < d.right) {
              newIndex = i;
              break;
            }
          }

          const inLeftBoundry = movingDay.current.leftBoundryIdx === -1 || newIndex > movingDay.current.leftBoundryIdx;
          const inRightBoundry = movingDay.current.rightBoundryIdx === -1 || newIndex < movingDay.current.rightBoundryIdx;

          if (newIndex !== xIndexRef.current && xPosition > 0 && newIndex !== -1 && inLeftBoundry && inRightBoundry) {
            xIndexRef.current = newIndex;
            let person = peopleAllRawData.current.find((peep) => peep.id === movingDay.current.person.id);
            let day = person.days.find((d) => d.id === movingDay.current.id);
            const newDate = dates.current[newIndex].date.getISODate();

            day.date = newDate;

            targetMovedJoinLeaveDay.current = dates.current[newIndex].date;

            buildGrid(startDate.current);
          }
        }
      });
    },
    [dotsContainerRef, movingDay, dates, xIndexRef, buildGrid]
  );

  const movedJoinLeaveDay = useCallback(async () => {
    if (targetMovedJoinLeaveDay.current) {
      let doUpdate = true;
      // If moved next to joins/leaves, then merge the two
      if (movingDay.current.system_type === "joins") {
        if (movingDay.current.leftBoundryIdx !== -1 && xIndexRef.current === movingDay.current.leftBoundryIdx + 1) {
          // Moved a "joins" left to the date just right of a "leaves", delete both
          const bounds = getDayBoundries(movingDay.current.person_id, targetMovedJoinLeaveDay.current);
          await supabase.from("days").delete().in("id", [bounds.day.id, bounds.leftBoundry.day.id]);
          doUpdate = false;
        }
      } else if (movingDay.current.system_type === "leaves") {
        if (movingDay.current.rightBoundryIdx !== -1 && xIndexRef.current === movingDay.current.rightBoundryIdx - 1) {
          // Moved a "leaves" to the date just left of a "joines", delete both
          const bounds = getDayBoundries(movingDay.current.person_id, targetMovedJoinLeaveDay.current);
          await supabase.from("days").delete().in("id", [bounds.day.id, bounds.rightBoundry.day.id]);
          doUpdate = false;
        }
      }

      if (doUpdate) await supabase.from("days").update({ date: targetMovedJoinLeaveDay.current.getISODate() }).eq("id", movingDay.current.id);
      fetchData(startDate.current);
    }

    document.removeEventListener("mousemove", handleMoveJoinLeaveDay);
    document.removeEventListener("mouseup", movedJoinLeaveDay);
    targetMovedJoinLeaveDay.current = null;
    movingDay.current = null;
  }, [fetchData, targetMovedJoinLeaveDay, movingDay, xIndexRef, getDayBoundries, supabase, handleMoveJoinLeaveDay]);

  const startDragJoinLeaveDay = useCallback(
    (personId, date, xOffset) => {
      const person = peopleAllRawData.current.find((peep) => peep.id === personId);
      const bounds = getDayBoundries(personId, date);
      movingDay.current = { ...bounds.day, xOffset, person, leftBoundryIdx: bounds.leftBoundry?.index || -1, rightBoundryIdx: bounds.rightBoundry?.index || -1 };

      document.addEventListener("mousemove", handleMoveJoinLeaveDay);
      document.addEventListener("mouseup", movedJoinLeaveDay);
    },
    [peopleAllRawData, movingDay, movedJoinLeaveDay, handleMoveJoinLeaveDay, getDayBoundries]
  );

  const removePerson = useCallback(
    async (personId, name) => {
      if (window.confirm("DANGER!\n\nAre you sure you'd like to remove all of " + name + "'s history of working on this project?")) {
        const { data } = await supabase.from("people").select().eq("id", personId);
        if (!data[0]?.uid && !data[0]?.email) {
          await supabase.from("people").delete().eq("id", personId);
        } else {
          await supabase.from("days").delete().eq("person_id", personId).eq("project_id", user.proj.id);
        }
        fetchData(startDate.current);
      }
    },
    [supabase, fetchData, user.proj.id]
  );

  const inviteUser = useCallback(
    async (id, email, name) => {
      const { data } = await supabase.from("people").select().eq("id", id);
      let redirectTo = "";

      if (!data[0].current_org_id) {
        // User is completely new to Peepz, redirect new user to password setting in invite email's link
        await supabase.from("people").update({ current_org_id: user.org.id, current_proj_id: user.proj.id }).eq("id", id);
        redirectTo = "password";
      }

      await supabase.from("memberships").update({ status: "invited", project_invite: user.proj.id }).eq("person_id", id).eq("org_id", user.org.id);

      await fetch("/.netlify/functions/inviteuser", {
        method: "POST",
        body: JSON.stringify({ email: email, id: id, name: name, redirectTo: redirectTo, inviter: user.firstname + " " + user.lastname }),
      });
      fetchData(startDate.current);
    },
    [supabase, user, fetchData]
  );

  const closeInviteUser = useCallback(() => {
    setShowInviteUser(false);
    fetchData(startDate.current);
  }, [setShowInviteUser, fetchData]);

  const resizeDebounce = useCallback((func) => {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 100, event);
    };
  }, []);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    showWeekends.current = user?.proj?.settings?.find((s) => s.name === "showweekends")?.value === "true" || false;
    fetchData(startDate.current);
    window.addEventListener("resize", resizeDebounce(buildGrid));
    return () => window.removeEventListener("resize", resizeDebounce(buildGrid));
  }, [fetchData, buildGrid, resizeDebounce, user, hideDeparted]);

  useGSAP(
    () => {
      const hasNonames = document.getElementsByClassName("nonameribbon").length > 0;
      if (!loading) {
        tl.current = gsap
          .timeline()
          .from(".dot", {
            scale: 0,
            duration: 0.09,
            stagger: 0.002,
          })
          .fromTo(
            ".ribbon",
            {
              opacity: 0,
              y: -100,
            },
            {
              opacity: 1,
              y: 0,
              duration: 0.14,
              ease: "power4.out",
              stagger: 0.04,
            },
            0.15
          );
        if (hasNonames) tl.current.fromTo(".nonameribbon", { opacity: 0, y: -50 }, { opacity: 0.6, y: 0, duration: 0.8, ease: "power2.out" });
      }
    },
    { dependencies: [loading] }
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const longestNameWidth = longestName.current * FONT_WIDTH;

  return (
    <>
      <GoalPopup showEditGoal={showEditGoal} editingGoal={editingGoal.current} submitGoal={submitGoal} closeGoal={closeGoal} deleteGoal={deleteGoal} />
      <AddPersonPopup
        showAddPerson={showAddPerson}
        addPersonDate={addPersonDate.current}
        closeAddPerson={closeAddPerson}
        submitAddPeople={submitAddPeople}
        list={peopleAllFormatted?.current?.map((p) => ({ value: p.person.firstname + " " + p.person.lastname, id: p.person.id }))}
      />
      <InviteUserPopup showInviteUser={showInviteUser} invitingUser={invitingUser.current} submitInviteCb={inviteUser} closeInviteUserCb={closeInviteUser} people={people} />

      {/* Top panel to select status for selected days in the grid */}
      <DaySelect show={showDaySelect} fontWidth={FONT_WIDTH} clickCb={submitDays} closeCb={closeDaySelect} />

      {/* Tutorial to add a milestone and learn to use date + button */}
      {goals?.current?.length <= 1 && (
        <Flex gap={1} style={{ marginLeft: "36%", alignItems: "start", position: "absolute", top: 30 }}>
          <div style={{ marginRight: -10, zIndex: 100 }}>
            <img alt="arrow" src={arrow} style={{ marginTop: 20, width: 150, transform: "rotate(-10deg) scaleX(-100%) scaleY(-100%) scale(0.8)" }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <h3 style={{ fontWeight: "bold" }}>Gotta goal?</h3>
            Set a first milestone or goal to aim for.
          </div>
        </Flex>
      )}

      <div ref={containerRef} style={{ visibility: loading ? "hidden" : "visible", paddingTop: 70 }}>
        {/* Arrows to change dates shown in grid, earlier or later */}
        <div style={{ position: "absolute", zIndex: 10 }}>
          {!todayInRange && <TodayButton onClickCb={() => buildGrid(new Date().subtractDays(10, true))} />}
          <FlexCenter gap={4} style={{ marginLeft: 10, marginTop: -10 }}>
            <CircleArrow onClickCb={() => buildGrid(startDate.current.subtractDays(30, true))} />
            <CircleArrow inverted onClickCb={() => buildGrid(startDate.current.addDays(30, true))} />
          </FlexCenter>
        </div>

        {/* Filters for the grid */}
        <div style={{ position: "absolute", zIndex: 10, right: 20, top: 80 }}>
          <Flex style={{ alignItems: "center" }} gap={0}>
            <FilterButton text="Departed" checked={hideDeparted} onClick={() => setHideDeparted(!hideDeparted)} />
          </Flex>
        </div>

        <FlexBottom gap={GRID_SPACE_WEEKEND}>
          <FlexLeftCol gap={GRID_SPACE_Y} style={{ paddingBottom: 2 }}>
            {people?.map((peep, i) => {
              const name = peep.person.firstname + (peep.person.lastname ? " " + peep.person.lastname.charAt(0) : "");
              const isMe = peep.person.id === user.id;
              return (
                <Row
                  key={"person" + i}
                  height={BOX_SIZE}
                  width={longestNameWidth}
                  name={name}
                  notInvited={peep.person.uid === null}
                  isMe={isMe}
                  grid_space_x={GRID_SPACE_X}
                  canEdit={roleAccess("manager")}
                  removePersonCb={() => removePerson(peep.person.id, peep.person.firstname + " " + peep.person.lastname)}
                  showInviteUser={() => {
                    invitingUser.current = peep.person;
                    setShowInviteUser(true);
                  }}
                />
              );
            })}
          </FlexLeftCol>

          <div ref={dotsContainerRef} style={{ position: "relative", width: "100%", display: "inline-block" }}>
            {/* Everything above the grid of days incl goals, day dates and month names */}
            <DatesGoals
              dates={dates?.current}
              goals={goals?.current?.filter((goal) => goal.type !== "unplanned")}
              size={BOX_SIZE}
              space_x={GRID_SPACE_X}
              space_y={GRID_SPACE_Y}
              animationDone={tl.current?.totalProgress() === 1}
              addGoalCb={(date) => {
                editingGoal.current = { date: date, type: "normal" };
                submitGoal(null, true);
              }}
              addPersonCb={(date) => {
                addPersonDate.current = date;
                setShowAddPerson(true);
              }}
              editGoalCb={(id, newIndex) => {
                if (typeof newIndex === "number") {
                  // Moved goal
                  submitGoal({ id: id, date: dates.current[newIndex].date.toISOString() });
                } else {
                  const goal = goals.current.find((g) => g.id === id);
                  editingGoal.current = goal;
                  setShowEditGoal(true);
                }
              }}
              showParentCb={() => {
                setLoading(false);
              }}
              hideAddButton={movingDay.current}
            />

            {/* Main loop displaying the grid of days */}
            <div style={{ position: "relative", height: people?.length * (BOX_SIZE + GRID_SPACE_Y) || 80 }}>
              {people?.map((peep, k) => {
                const isMe = user.id === peep.person.id;

                return peep.days.map((day, m) => {
                  const selected = selectedDays?.current?.find((d) => day.personIndex === d.row && day.dayIndex === d.col);
                  const canShowPopup = selectedDays.current ? selectedDays.current.length <= 1 : true;
                  return day.isActive ? (
                    <Dot
                      key={"day" + m}
                      data={day}
                      row={k}
                      size={BOX_SIZE}
                      space_y={GRID_SPACE_Y}
                      callback={selectDay}
                      selected={selected}
                      canShowPopup={canShowPopup && !day.isFirstOrLast}
                      leaveProjectCb={() => personLeaveProject(peep.person.id, day)}
                      isFirstOrLast={day.isFirstOrLast}
                      dates={dates?.current}
                      startDragJoinLeaveDayCb={(date, xOffset) => startDragJoinLeaveDay(peep.person.id, date, xOffset)}
                      canEdit={roleAccess("lead") || (isMe && day.day >= new Date())}
                      canLeave={roleAccess("manager")}
                    />
                  ) : (
                    <div key={"day" + m} />
                  );
                });
              })}
            </div>
          </div>
        </FlexBottom>
      </div>
    </>
  );
}

const FilterButton = ({ text, checked = false, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => onClick()}
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px 10px",
        border: `1px solid ${hover ? "#ffffff88" : checked ? "transparent" : "#ffffff44"}`,
        borderRadius: 4,
        fontSize: 10,
        color: hover ? "#ffffffaa" : checked ? "#ffffff88" : "#ffffff66",
        background: checked ? "#ffffff44" : "transparent",
        userSelect: "none",
        fontWeight: "normal",
        cursor: "pointer",
      }}
    >
      {text}
    </div>
  );
};
