import React, { useState, useRef, useCallback } from "react";
import { Flex, Form } from "styles/global-style";
import Input from "components/Input";
import Button from "components/Button";
import PillDropdown from "components/PillDropdown";
import useKey from "utils/KeyListener";

import trashcan from "imgs/trashcan.png";

export default function EditTask({ submitTask, deleteTask, editingTask, users }) {
  const [inputValid, setInputValid] = useState(false);
  const [focus, setFocus] = useState(0);

  const newTitle = useRef();
  const ownerInput = useRef();
  const components = useRef([newTitle, ownerInput]);

  useKey("Enter", false, () => {
    if (focus < components.current.length - 1) {
      components.current[focus + 1].current.focus();
      setFocus(focus + 1);
    } else {
      setFocus(0);
    }
  });

  useKey("Enter", true, () => {
    submit();
  });

  const validateInput = useCallback(() => {
    setInputValid(newTitle.current && newTitle.current.value.trim());
  }, [setInputValid, newTitle]);

  const submit = useCallback(
    (props, keepOpen) => {
      submitTask(
        {
          id: editingTask.task.id,
          title: newTitle.current.value.trim(),
          description: null /*descInput.current.value*/,
          estimate: null /*estimateInput.current.value*/,
          person_id: props ? (props.ownerId === "remove" ? null : typeof props.ownerId === "number" ? props.ownerId : editingTask.task.person_id) : editingTask.task.person_id,
        },
        keepOpen
      );
    },
    [submitTask, editingTask, newTitle]
  );

  const removeTask = useCallback(() => {
    if (deleteTask && editingTask.task.id > -1) {
      if (window.confirm("Are you sure you want to delete this task?")) {
        deleteTask(editingTask.task.id);
      }
    }
  }, [deleteTask, editingTask]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
      gap={0}
    >
      <Flex>
        <Input
          ref={newTitle}
          placeholder="Task title here..."
          onChange={() => validateInput()}
          autoFocus
          left
          value={editingTask?.task?.title}
          noborder
          customStyle={{ padding: 0, border: "none", outline: "none", fontSize: 28, fontWeight: "bold" }}
          onFocusCb={() => setFocus(0)}
        />
      </Flex>

      {editingTask.task.id > -1 && (
        <img alt="delete" src={trashcan} style={{ position: "absolute", height: 20, top: 20, right: 20, opacity: 0.7, cursor: "pointer" }} onClick={() => removeTask()} />
      )}

      <Flex style={{ marginBottom: 20 }}>
        <PillDropdown
          ref={ownerInput}
          list={users.map((val) => ({
            value: !val.lastname ? val.firstname : val.firstname + " " + val.lastname.charAt(0),
            id: val.id,
          }))}
          selectedIdx={users.findIndex((item) => item.id === editingTask?.task?.owner_id)}
          placeholder={"Owner"}
          setFocusCb={() => setFocus(1)}
          onSelect={(item) => submit({ ownerId: item.id }, true)}
          onClear={() => submit({ ownerId: "remove" }, true)}
          pillStyle={{
            background: "#999",
            color: "white",
            border: "1px solid #999",
            borderRadius: 18,
          }}
        />
      </Flex>

      {/*<Flex>
        <Button text="Get AI estimate" callback={getGpt} color={"purple"} width={100} />
        {gpt && <div style={{ color: "black" }}>{gpt}</div>}
      </Flex>*/}

      <Button text="Submit" callback={() => submit()} large color="purple" disabled={!inputValid} />
    </Form>
  );
}
