import React from "react";
import { checkboxStyle } from "styles/global-style";

export default function Checkbox({ text, checked, onChangeCb, white }) {
  return (
    <label style={{ ...checkboxStyle, color: white ? "#fff" : "#999" }}>
      <input type="checkbox" checked={checked} onChange={onChangeCb} style={{ height: 16, width: 16 }} />
      {text}
    </label>
  );
}
