import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import { GoalStyles } from "styles/global-style";

const MIN_PILLAR_HEIGHT = 20;

export default memo(function Ribbon({
  name,
  id,
  dateIndex,
  dates,
  type,
  x = 0,
  y = 0,
  z,
  space_x = 0,
  animationDone = true,
  onClick,
  containerLeft,
  movedGoalCb,
  disabled,
  color,
  pillarWidth = 6,
}) {
  const [xIndex, setXIndex] = useState(dateIndex);
  const [xPos, setXPos] = useState(x);

  const containerRef = useRef();
  const xOffsetRibbon = useRef();
  const animationFrameId = useRef();
  const didDrag = useRef();
  const tempXIndex = useRef();
  const handleDragRef = useRef();
  const xIndexRef = useRef(xIndex);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const onMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      if (disabled) return;

      if (didDrag.current) {
        movedGoalCb(id, tempXIndex.current);
        didDrag.current = false;
      } else {
        onClick(id);
      }

      document.removeEventListener("mousemove", handleDragRef.current);
      document.removeEventListener("mouseup", onMouseUp);
    },
    [handleDragRef, tempXIndex, onClick, movedGoalCb, disabled, id]
  );

  const onMouseDown = useCallback(
    (e) => {
      if (disabled) return;
      e.preventDefault();
      const rect = containerRef.current.getBoundingClientRect();
      xOffsetRibbon.current = e.clientX - rect.left;
      document.addEventListener("mousemove", handleDragRef.current);
      document.addEventListener("mouseup", onMouseUp);
    },
    [xOffsetRibbon, handleDragRef, containerRef, onMouseUp, disabled]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    const handleDrag = (e) => {
      if (disabled) return;

      // We use requestAnimationFrame to throttle updates
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      animationFrameId.current = requestAnimationFrame(() => {
        const xPosition = e.clientX - containerLeft - xOffsetRibbon.current - pillarWidth / 2;

        // Calculate which div the mouse is over
        let newIndex = -1;
        for (let i = 0; i < dates.length; i++) {
          const d = dates[i];
          if (d.left < xPosition && xPosition < d.right) {
            newIndex = i;
            break;
          }
        }
        if (newIndex !== xIndexRef.current && xPosition > 0 && newIndex !== -1) {
          tempXIndex.current = newIndex;
          didDrag.current = true;
          setXIndex(newIndex);
          xIndexRef.current = newIndex;
          setXPos(dates[newIndex].left);
        }
      });
    };
    handleDragRef.current = handleDrag;

    return () => {
      document.removeEventListener("mousemove", handleDragRef.current);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [containerLeft, dates, disabled, onMouseUp, xIndexRef, pillarWidth]);

  useEffect(() => {
    setXPos(x);
  }, [x]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    <div
      ref={containerRef}
      className={name && name.length > 0 ? "ribbon" : "nonameribbon"}
      style={{
        opacity: animationDone ? 1 : 0,
        display: "inline-block",
        position: "absolute",
        left: xPos + pillarWidth / 2 + space_x,
        bottom: MIN_PILLAR_HEIGHT + y,
        zIndex: z,
        transition: "x 120ms easeOut",
      }}
    >
      {/* Pillar */}
      <div
        style={{
          position: "absolute",
          left: 0,
          top: GoalStyles[type].height,
          bottom: 6,
          width: pillarWidth,
          height: MIN_PILLAR_HEIGHT + y - 4,
          backgroundImage: `linear-gradient(rgb(50, 34, 4) 1px, ${color ? color : GoalStyles[type].color} 8px)`,
        }}
      />
      {/* Peg */}
      <div
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          top: GoalStyles[type].height + y + MIN_PILLAR_HEIGHT - 4,
          borderLeft: `${pillarWidth / 2}px solid transparent`,
          borderRight: `${pillarWidth / 2}px solid transparent`,
          borderTop: `${pillarWidth / 2}px solid ${color ? color : GoalStyles[type].color}`,
        }}
      />

      {/* Ribbon */}
      <div
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={{
          display: "inline-flex",
          transform: "skew(-10deg)",
          transformOrigin: "bottom left",
          background: color ? color : GoalStyles[type].color,
          cursor: disabled ? "default" : "pointer",
          boxShadow: "#00000055 1px 4px 5px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            alignItems: "center",
            textShadow: "rgba(0, 0, 0, 0.7) 1px 1px 0px",
            fontSize: GoalStyles[type].fontSize,
            height: GoalStyles[type].height,
            padding: `0px ${GoalStyles[type].padding}px`,
            fontWeight: "bold",
            userSelect: "none",
            transform: "skew(10deg)",
          }}
        >
          {name ? name : "unnamed"}
        </div>
      </div>
    </div>
  );
});
