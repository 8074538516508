import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { AuthContext } from "utils/AuthContext";
import { FlexCenter, FlexCenterCol, DayStyles } from "styles/global-style";
import useKey from "utils/KeyListener";

import arrow from "imgs/arrow-right.png";
import xmark from "imgs/xmark.png";

export default function DaySelect({ show, clickCb, closeCb }) {
  const { user, supabase, roleAccess } = useContext(AuthContext);

  const buttonWidthRef = useRef();

  const [data, setData] = useState();

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const fetchData = useCallback(async () => {
    const res = await supabase.from("day_types").select().eq("org_id", user.org.id).order("id");
    const longestName = res?.data?.reduce((acc, curr) => (acc < curr.name.length ? curr.name.length : acc), 0);
    buttonWidthRef.current = longestName * 4;
    setData(res?.data);
  }, [supabase, user]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useKey("Escape", false, () => {
    if (closeCb) closeCb();
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FlexCenter style={{ position: "absolute", height: 90, width: "100%", top: show ? 0 : -150, background: "white", zIndex: 10000, transition: "all 150ms ease-out" }}>
      {data && (
        <>
          <DayLarge icon={DayStyles.selected.icon} color={DayStyles.selected.color} />
          <img alt="arrow" src={arrow} style={{ height: 20, opacity: 0.3, padding: "0px 20px 14px 20px" }} />

          {data.map((type, i) => {
            if (!roleAccess(type.role_required)) return <div key={"daytype" + i} />;
            const icon = DayStyles[type.style]?.icon || null;
            const color = DayStyles[type.style]?.color || null;
            const border = DayStyles[type.style]?.border || null;
            return (
              <DayLarge
                key={"daytype" + i}
                icon={icon}
                color={color}
                border={border}
                name={type.name}
                width={buttonWidthRef.current}
                callback={() => clickCb(type.id, type.style)}
              />
            );
          })}

          <img alt="close" src={xmark} style={{ height: 20, opacity: 0.3, padding: "0px 20px 14px 20px", cursor: "pointer" }} onClick={closeCb} />
        </>
      )}
    </FlexCenter>
  );
}

const DayLarge = ({ color, border, icon, name, width, callback }) => {
  const [hover, setHover] = useState();
  return (
    <div style={{ width: width, cursor: "pointer" }} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={() => (callback ? callback() : null)}>
      <FlexCenterCol gap={2}>
        <div
          style={{
            height: 38,
            width: 38,
            borderRadius: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: color,
            border: border,
            boxSizing: "border-box",
            transform: hover ? "scale(1.08)" : "scale(1)",
          }}
        >
          {icon && <img alt="daytypeicon" src={icon} style={{ height: "40%", opacity: 0.3, userSelect: "none" }} />}
        </div>
        <div style={{ fontSize: "0.6rem", fontWeight: 600, color: "gray", height: 15, textAlign: "center", userSelect: "none", cursor: "pointer" }}>{name?.toUpperCase()}</div>
      </FlexCenterCol>
    </div>
  );
};
