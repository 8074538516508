import React, { useRef, useState } from "react";
import Cropper from "react-cropper";

import { Dialog } from "@headlessui/react";

import Button from "../Button";

import "cropperjs/dist/cropper.css";
import "./style.css";

export const ImageCropper = (props) => {
  const { thumb } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFilePath, setCroppedFilePath] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const cropper = useRef(null);
  const fileInputRef = useRef(null);

  const handleClose = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setOpenDialog(false);
  };

  const fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setSelectedFile(newFile);
      setOpenDialog(true);
    }
  };

  const onCrop = () => {
    const str = cropper.current.cropper
      .getCroppedCanvas({
        width: 1280,
        height: 720,
      })
      .toDataURL(selectedFile.type, 1);

    setCroppedFilePath(str);
    base64StringtoFile(str, selectedFile.name, "image/jpeg").then((file) => {
      setCroppedFile(file);
    });
  };

  function base64StringtoFile(url, filename, mimeType) {
    if (url.startsWith("data:")) {
      var arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  const confirmCroppedImage = async () => {
    onFileSubmit().then(() => {
      handleClose();
    });
  };

  async function onFileSubmit(e) {
    if (props.onFileSubmit) props.onFileSubmit(selectedFile, croppedFile, thumb);
  }

  return (
    <>
      <div className="imageContainer">
        <label className="label-image">
          {thumb ? <img src={thumb} alt="Uploaded" className="image" /> : croppedFilePath && <img src={croppedFilePath} alt="Uploaded" className="image" />}
          <div className="card">
            <input ref={fileInputRef} type="file" accept="image/png, image/jpeg, image/gif" onChange={fileSelectHandler} className="fileInput" />
            {thumb ? <span className="change-picture">Change picture</span> : <span>Upload picture</span>}
          </div>
        </label>
      </div>
      <Dialog as="div" className="dialog" open={openDialog} onClose={handleClose}>
        <Dialog.Panel>
          <Dialog.Title className="title">{thumb ? <div>Change picture</div> : <div>Upload picture</div>}</Dialog.Title>
          <div style={{ display: "flex" }}>
            {selectedFile && (
              <Cropper
                ref={cropper}
                style={{ height: 400, marginBottom: 30 }}
                aspectRatio={1}
                src={selectedFile?.preview}
                preview=".img-preview"
                guides={false}
                crop={onCrop}
                checkOrientation={false}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                responsive={true}
                autoCrop={1}
              />
            )}
          </div>

          <Button text="Confirm cropping" callback={confirmCroppedImage} large color="black" />
          <br />
          <Button text="Cancel" callback={handleClose} color="red" />
        </Dialog.Panel>
      </Dialog>
    </>
  );
};
