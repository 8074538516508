import React, { useState, useEffect, useCallback, useContext } from "react";
import Button from "./Button";
import { Colors, PADDING } from "styles/global-style";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "utils/AuthContext";

import logo from "imgs/logo_white.png";
import hamburger from "imgs/menubars.png";

const LOGO_MAXWIDTH = 150;
const LOGO_MINWIDTH = 100;

export default function MainMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const [mod, setMod] = useState({
    width: LOGO_MAXWIDTH,
    factor: 0,
  });
  const [open, setOpen] = useState(false);

  const handleScroll = useCallback(() => {
    const scroll = Math.max(window.scrollY, 1);
    const factor = Math.min(scroll, 100) / 100;

    setMod({
      factor: factor,
      width: LOGO_MAXWIDTH - factor * (LOGO_MAXWIDTH - LOGO_MINWIDTH),
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div>
        <div
          style={{
            position: "fixed",
            background: "rgba(78, 41, 170, 1)",
            borderRadius: 6,
            padding: "0px " + PADDING + "px",
            top: -(PADDING + 4),
            left: PADDING,
            zIndex: 101,
          }}
        >
          <img
            alt="logo"
            src={logo}
            style={{
              paddingTop: PADDING + 10,
              width: mod.width,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        <div
          style={{
            position: "fixed",
            background: "white",
            borderRadius: 6,
            top: -100 + 100 * mod.factor,
            right: PADDING,
            height: PADDING * 2,
            padding: "4px " + PADDING / 2 + "px",
            zIndex: 101,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="hamburger"
            src={hamburger}
            style={{ width: 35, filter: "invert(1)", cursor: "pointer" }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        </div>

        <div
          className="mobileMenu"
          style={{
            position: "relative",
            left: 0,
            top: 0,
            width: "100%",
            zIndex: 100,
          }}
        >
          <div
            style={{
              margin: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              src={logo}
              style={{
                width: 150,
                cursor: "pointer",
                zIndex: 2,
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            {!user && (
              <div
                className="hamburger"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img alt="hamburger" src={hamburger} />
              </div>
            )}
          </div>
        </div>

        <div
          className="desktopMenu"
          style={{
            display: "flex",
            position: "relative",
            padding: PADDING / 2 + "px " + PADDING * 2 + "px",
            zIndex: 100,
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {!user && (
            <>
              <div className="menu" style={{ display: "flex" }}>
                <Button text="Join" click="/join" menuitem active={location.pathname.includes("join")} />
                <Button text="Login" click="/login" menuitem active={location.pathname.includes("login")} />
              </div>
              <div
                className="hamburger"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
              >
                <img alt="hamburger" src={hamburger} />
              </div>
            </>
          )}
        </div>

        {!user && open && (
          <div
            className="menu-mobile"
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              verticalAlign: "middle",
              transition: "200ms ease",
              overflow: "hidden",
              background: "#ffffff88",
              backdropFilter: "blur(6px)",
              zIndex: 1000,
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "center",
                background: Colors.GREEN,
                width: "100%",
                height: "100%",
              }}
              onClick={(e) => {
                setOpen(false);
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 18,
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <i className="fas fa-home" style={{ fontSize: 31, color: "white" }} />
              </div>

              <Button
                text="Login"
                click="/login"
                green
                large
                callback={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
