import React, { useState } from "react";

import _dots from "imgs/dots.png";
import _plus from "imgs/plus.png";

export default function ButtonTiny({ disabled, callback, plus, dots, text, active, light, textAlways }) {
  const [hovering, setHovering] = useState(false);
  const [down, setDown] = useState(false);

  const handleMouseEnter = () => {
    if (disabled) return;
    setHovering(true);
  };

  const handleMouseLeave = () => {
    if (disabled) return;
    setDown(false);
    setHovering(false);
  };

  const handleMouseUp = () => {
    if (disabled) return;
    if (callback) callback();
    setDown(false);
  };

  const handleMouseDown = () => {
    if (disabled) return;
    setDown(true);
  };

  let bg = hovering ? "#ffffff11" : "none";
  if (active) bg = hovering ? "#ffffff55" : "#ffffff33";

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: bg,
        width: 22,
        height: 22,
        borderRadius: 999,
        cursor: disabled ? "initial" : "pointer",
        opacity: disabled ? 0.3 : 1,
        userSelect: "none",
        transform: down ? "scale(0.97)" : "scale(1)",
        flexGrow: "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {plus && <img alt="tinybutton" src={_plus} style={{ width: "90%", filter: light ? "brightness(1)" : "brightness(0.1)" }} />}
      {dots && <img alt="tinybutton" src={_dots} style={{ width: "60%", filter: "invert(1)" }} />}
      {text && (
        <div
          style={{
            position: "absolute",
            left: hovering || textAlways ? 28 : 16,
            opacity: hovering || textAlways ? 1 : 0,
            transition: "all 140ms ease-out",
            fontSize: 10,
            whiteSpace: "nowrap",
            color: "grey",
            fontWeight: "bold",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
}
