import React, { useState } from "react";
import { GoalStyles } from "styles/global-style";

export default function TodayButton({ onClickCb }) {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        position: "absolute",
        display: "inline-flex",
        transform: hover ? "skew(-10deg) scale(1.05)" : "skew(-10deg) scale(1)",
        cursor: "pointer",
        boxShadow: "#00000055 1px 2px 5px 1px",
        marginLeft: 18,
        marginTop: -40,
        border: "1px solid red",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          alignItems: "center",
          textShadow: "rgba(0, 0, 0, 0.7) 1px 1px 0px",
          fontSize: GoalStyles.today.fontSize - 1,
          height: 16,
          padding: `0px ${GoalStyles.today.padding}px`,
          fontWeight: "bold",
          userSelect: "none",
          transform: "skew(10deg)",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          if (onClickCb) onClickCb(e);
        }}
      >
        TODAY
      </div>
    </div>
  );
}
