import React, { useCallback, useLayoutEffect, useState, useRef, useContext } from "react";
import { FlexLeftCol, FlexLeftColNoGap, Form, GoalStyles, FlexCenter } from "styles/global-style";
import { AuthContext } from "utils/AuthContext";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import Story from "components/Cards/Story";
import EditTask from "components/Cards/EditTask";

import arrow from "imgs/arrow.png";
import ButtonTiny from "components/ButtonTiny";

const clone = require("rfdc")();

export default function Cards() {
  const { user, supabase } = useContext(AuthContext);

  const [goals, setGoals] = useState();
  const [loading, setLoading] = useState(true);
  const [editingStory, setEditingStory] = useState();
  const [editingTask, setEditingTask] = useState(null); // {story: {id, index}, col: {id, index}} or {task: {id}}
  const [inputValid, setInputValid] = useState(false);
  const [goalsHidden, setGoalsHidden] = useState([]);
  const [gpt, setGpt] = useState();

  const newTitle = useRef();
  const users = useRef();

  //----------------------------------------------------

  const fetchData = useCallback(async () => {
    const { data } = await supabase
      .from("goals")
      .select("*, stories(*, columns(*, tasks(*)))")
      .eq("project_id", user.proj.id)
      .order("date")
      .order("id", { foreignTable: "stories.columns", ascending: true });

    const { data: _users } = await supabase.from("memberships").select("*, people(*)").eq("org_id", user.org.id).order("firstname", { foreignTable: "people", ascending: true });
    users.current = _users.map((person) => ({ id: person.id, firstname: person.people.firstname, lastname: person.people.lastname }));

    // TODO: currently picking first milestone, enable multiple later
    setGoals(data);
    setGoalsHidden(user.proj.settings.find((s) => s.name === "goalsHidden") || []);
    setLoading(false);
  }, [supabase, user.proj.id, user.proj.settings, user.org.id]);

  const submitStory = useCallback(async () => {
    if (newTitle.current?.value.trim()) {
      if (editingStory?.title) {
        // Editing a story
        await supabase.from("stories").update({ title: newTitle.current.value.trim() }).eq("id", editingStory.id);
      } else {
        // Adding a new story
        const res = await supabase.from("stories").insert({ title: newTitle.current.value.trim(), goal: editingStory.goal.id }).select("id");
        const goalIdx = goals.findIndex((g) => g.id === editingStory.goal.id);
        const newOrder = goals[goalIdx].stories_order?.concat(res.data[0].id) || [res.data[0].id];
        await supabase.from("goals").update({ stories_order: newOrder }).eq("id", editingStory.goal.id);
      }
      setEditingStory(false);
      fetchData();
    }
  }, [supabase, fetchData, goals, editingStory]);

  const submitTask = useCallback(
    async (task, keepOpen) => {
      let newTaskId;
      if (editingTask.task.id > -1) {
        // Editing a task
        await supabase.from("tasks").update({ title: task.title, person_id: task.person_id, description: task.description, estimate: task.estimate }).eq("id", editingTask.task.id);
      } else {
        // Adding a new task
        const goalIdx = goals.findIndex((g) => g.id === editingTask.goal.id);
        const storyIdx = goals[goalIdx].stories.findIndex((s) => s.id === editingTask.story.id);
        const colIdx = goals[goalIdx].stories[storyIdx].columns.findIndex((c) => c.id === editingTask.col.id);

        const res = await supabase.from("tasks").insert({ title: task.title, column_id: editingTask.col.id }).select("id");
        newTaskId = res.data[0].id;
        const newOrder = goals[goalIdx].stories[storyIdx].columns[colIdx].tasks_order?.concat(newTaskId) || [newTaskId];
        await supabase.from("columns").update({ tasks_order: newOrder }).eq("id", editingTask.col.id);
      }

      if (!keepOpen) setEditingTask(null);
      else if (newTaskId) setEditingTask({ ...editingTask, task: { id: newTaskId } });
      fetchData();
    },
    [fetchData, supabase, editingTask, goals]
  );

  const deleteTask = useCallback(
    async (id) => {
      const goalIdx = goals.findIndex((g) => g.id === editingTask.goal.id);
      const storyIdx = goals[goalIdx].stories.findIndex((s) => s.id === editingTask.story.id);
      const colIdx = goals[goalIdx].stories[storyIdx].columns.findIndex((c) => c.id === editingTask.col.id);

      let newOrder = goals[goalIdx].stories[storyIdx].columns[colIdx].tasks_order.filter((val) => val !== id);
      if (newOrder.length === 0) newOrder = null;

      await supabase.from("columns").update({ tasks_order: newOrder }).eq("id", goals[goalIdx].stories[storyIdx].columns[colIdx].id);
      await supabase.from("tasks").delete().eq("id", id);
      setEditingTask(null);
      fetchData();
    },
    [fetchData, supabase, goals, editingTask]
  );

  const deleteStory = useCallback(
    async (indexPath, id, title) => {
      if (window.confirm("Delete story: " + title + "\n\n Are you sure?")) {
        let newOrder = goals[indexPath[0]].stories_order.filter((val) => val !== id);
        if (newOrder.length === 0) newOrder = null;
        await supabase.from("goals").update({ stories_order: newOrder }).eq("id", goals[indexPath[0]].id);
        await supabase.from("stories").delete().eq("id", id);
        fetchData();
      }
    },
    [goals, fetchData, supabase]
  );

  const validateText = useCallback(() => {
    setInputValid(newTitle.current && newTitle.current.value.trim());
  }, []);

  const draggedStory = useCallback(
    async (sourcePath, destPath, res) => {
      const { destination, source } = res;
      let _goals = clone(goals);

      // Remove story from source destination
      const storyId = _goals[sourcePath[0]].stories_order.splice(source.index, 1)[0];
      const story = _goals[sourcePath[0]].stories.splice(
        _goals[sourcePath[0]].stories.findIndex((s) => s.id === storyId),
        1
      )[0];

      // If no remaining stories, set storage to null
      if (_goals[sourcePath[0]].stories_order && _goals[sourcePath[0]].stories_order.length < 1) _goals[sourcePath[0]].stories_order = null;
      if (_goals[sourcePath[0]].stories && _goals[sourcePath[0]].stories.length < 1) _goals[sourcePath[0]].stories = null;

      // Add story to destination position
      if (_goals[destPath[0]].stories_order?.length > 0) _goals[destPath[0]].stories_order.splice(destination.index, 0, storyId);
      else _goals[destPath[0]].stories_order = [storyId];
      if (_goals[destPath[0]].stories?.length > 0) _goals[destPath[0]].stories.push(story);
      else _goals[destPath[0]].stories = [story];

      setGoals(_goals);
      if (_goals[sourcePath[0]].id !== _goals[destPath[0]].id)
        await supabase.from("goals").update({ stories_order: _goals[sourcePath[0]].stories_order }).eq("id", _goals[sourcePath[0]].id);
      await supabase.from("goals").update({ stories_order: _goals[destPath[0]].stories_order }).eq("id", _goals[destPath[0]].id);
      await supabase.from("stories").update({ goal: _goals[destPath[0]].id }).eq("id", storyId);

      fetchData();
    },
    [fetchData, goals, supabase]
  );

  const dragEnded = useCallback(
    async (res) => {
      const { destination } = res;

      // Bail if dropped outside or in source column
      if (!destination) return;

      // Indices [goal, story, column, task] for task source and destination
      const sourcePath = res.draggableId.split("-").map((v) => parseInt(v, 10));
      const destPath = destination.droppableId.split("-").map((v) => parseInt(v, 10));

      if (res.type === "story") {
        draggedStory(sourcePath, destPath, res);
        return;
      }

      const sourceStoryId = goals[sourcePath[0]].stories_order[sourcePath[1]];
      const sourceStoryIdx = goals[sourcePath[0]].stories.findIndex((s) => s.id === sourceStoryId);
      const destStoryId = goals[destPath[0]].stories_order[destPath[1]];
      const destStoryIdx = goals[destPath[0]].stories.findIndex((s) => s.id === destStoryId);

      let sourceCol = goals[sourcePath[0]].stories[sourceStoryIdx].columns[sourcePath[2]];
      let destCol = goals[destPath[0]].stories[destStoryIdx].columns[destPath[2]];

      // First remove from source column
      const taskId = sourceCol.tasks_order.splice(sourcePath[3], 1)[0];
      const task = sourceCol.tasks.splice(
        sourceCol.tasks.findIndex((t) => t.id === taskId),
        1
      )[0];

      // If no remaining tasks, set storage to null
      if (sourceCol.tasks_order && sourceCol.tasks_order.length < 1) sourceCol.tasks_order = null;
      if (sourceCol.tasks && sourceCol.tasks.length < 1) sourceCol.tasks = null;

      // Add task to destination position
      if (destCol.tasks_order?.length > 0) destCol.tasks_order.splice(destination.index, 0, taskId);
      else destCol.tasks_order = [taskId];
      if (destCol.tasks?.length > 0) destCol.tasks.push(task);
      else destCol.tasks = [task];

      // Calculate and store task time in column
      const now = Date.now();
      const history = task.history ? JSON.parse(task.history) : {};
      const lastMove = history.lastMove || task.created_at;

      history[sourceCol.id] = (history[sourceCol.id] || 0) + now - new Date(lastMove).getTime();
      history.lastMove = now;

      // Execute updates into DB
      if (sourceCol.id !== destCol.id) await supabase.from("columns").update({ tasks_order: sourceCol.tasks_order }).eq("id", sourceCol.id);
      await supabase.from("columns").update({ tasks_order: destCol.tasks_order }).eq("id", destCol.id);
      await supabase
        .from("tasks")
        .update({ column_id: destCol.id, history: JSON.stringify(history) })
        .eq("id", taskId);

      fetchData();
    },
    [fetchData, supabase, draggedStory, goals]
  );

  const getGpt = useCallback(() => {
    const goalIdx = goals.findIndex((g) => g.id === editingTask.goal.id);
    const story = goals[goalIdx].stories.find((sty) => sty.id === editingTask.story.id);

    fetch("/.netlify/functions/gpt", {
      method: "POST",
      body: JSON.stringify({ task: newTitle.current.value.trim(), project_desc: user.proj.description, story: story.title }),
    })
      .then((res) => res.json())
      .then((data) => {
        const _data = JSON.parse(data.message);
        setGpt(_data.content);
      });
  }, [setGpt, editingTask, user, goals]);

  const toggleGoal = useCallback(
    async (id) => {
      let newGoalsHidden = clone(goalsHidden);
      // const settingIdx = goalsHidden.findIndex((goal) => goal.id);

      const currentIdx = newGoalsHidden.findIndex((_id) => _id === id);
      if (currentIdx > -1) {
        newGoalsHidden.splice(currentIdx, 1);
      } else {
        newGoalsHidden.push(id);
      }

      setGoalsHidden(newGoalsHidden);

      // TODO: store settings in db, also add user_id to database
      // if (settingIdx > -1) {
      //   newGoalsHidden.splice(settingIdx, 1);
      //   // await supabase.from("settings").update({ value: value }).eq("id", setting.id);
      // } else {
      // }
      // // await supabase.from("settings").insert({ name: name, value: value, project_id: user.proj.id });
    },
    [goalsHidden, setGoalsHidden]
  );

  const getStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#ffffff22" : "none",
    padding: "0px 20px",
    borderRadius: 14,
  });

  //----------------------------------------------------

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <FlexLeftCol>
        <DragDropContext onDragEnd={dragEnded}>
          {!loading &&
            goals &&
            goals.map((goal, k) => {
              const isUnplanned = goal.type === "unplanned";
              const isHidden = goalsHidden.includes(goal.id);
              const color = GoalStyles[goal.type]?.color || "transparent";
              return (
                <Droppable key={k + "-"} droppableId={k + "-"} type="story">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ ...getStyle(snapshot.isDraggingOver), marginBottom: isHidden ? 0 : 40 }}>
                      <div>
                        <FlexCenter>
                          {goals.length > 1 && (
                            <>
                              <div style={{ width: 19 }} />
                              <RibbonRound
                                name={isUnplanned ? "Unplanned stories" : goal.name}
                                type={"massive"}
                                color={color}
                                noBackground={isUnplanned}
                                isHidden={isHidden}
                                onClick={() => {
                                  toggleGoal(goal.id);
                                }}
                              />
                            </>
                          )}
                          <ButtonTiny plus light text="Add story" callback={() => setEditingStory({ goal: { id: goal.id } })} textAlways={goals.length <= 1} />
                        </FlexCenter>
                        <div style={{ height: isHidden ? 0 : "calc-size(auto, size)", transition: "height 200ms ease" }}>
                          {goal.stories_order?.map((story_id, i) => {
                            const story = goal.stories.find((stry) => stry.id === story_id);
                            return (
                              <FlexLeftColNoGap key={"keystory" + i}>
                                <Story
                                  indexPath={[k, i]}
                                  goalId={goal.id}
                                  story={story}
                                  deleteStoryCb={deleteStory}
                                  index={i}
                                  addTaskCb={(story_id, story_idx, col_id, col_idx) => {
                                    setEditingTask({ goal: { id: goal.id }, story: { id: story_id, index: story_idx }, col: { id: col_id, index: col_idx }, task: { id: -1 } });
                                  }}
                                  onClickStoryCb={(goalId, storyId) => {
                                    const goal = goals.find((g) => g.id === goalId);
                                    const story = goal.stories.find((st) => st.id === storyId);
                                    setEditingStory({ goal: { id: goal.id }, id: storyId, title: story.title });
                                  }}
                                  editTaskCb={(task, story, col, colIdx) => {
                                    setEditingTask({
                                      goal: { id: goal.id },
                                      story: { id: story.id },
                                      col: { id: col.id, index: colIdx },
                                      task: {
                                        id: task.id,
                                        title: task.title,
                                        owner_id: task.person_id,
                                        description: task.description,
                                        estimate: task.estimate,
                                        history: task.history,
                                      },
                                    });
                                  }}
                                  users={users.current}
                                />
                              </FlexLeftColNoGap>
                            );
                          })}
                        </div>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
        </DragDropContext>
        <div style={{ marginBottom: 80 }} />
      </FlexLeftCol>

      {!loading && goals.length === 1 && goals[0].type === "unplanned" && !goals[0].stories_order && (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          <center>
            <h2>Let's go!</h2>
            <br />
            What do you want to achieve first?
          </center>
          <div style={{ position: "absolute", left: "38%", top: 0 }}>
            <img alt="arrow" src={arrow} style={{ width: 200, transform: "rotate(58deg) scaleX(-1)" }} />
          </div>
        </div>
      )}

      {editingStory && (
        <Modal closeCb={() => setEditingStory(false)}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitStory();
            }}
          >
            <h2 style={{ color: "#000", marginTop: -11 }}>{editingStory?.title ? "Edit Story" : "New Story"}</h2>
            <Input ref={newTitle} caption="Title" placeholder="What to achieve here..." onChange={() => validateText()} autoFocus left value={editingStory?.title} />
            <Button text="Submit" callback={() => submitStory()} large color="purple" disabled={!inputValid} />
          </Form>
        </Modal>
      )}

      {editingTask && (
        <Modal closeCb={() => setEditingTask(false)}>
          <EditTask submitTask={submitTask} gpt={gpt} getGpt={getGpt} editingTask={editingTask} users={users.current} deleteTask={(id) => deleteTask(id)} />
        </Modal>
      )}
    </>
  );
}

const RibbonRound = ({ color, type, name, opacity, noBackground, isHidden, onClick }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        background: noBackground || isHidden ? "transparent" : color ? color : GoalStyles[type].color,
        cursor: "pointer",
        boxShadow: noBackground || isHidden ? "none" : "#00000055 1px 4px 5px 0px",
        opacity: isHidden ? 0.6 : opacity,
        minWidth: 280,
        borderRadius: 4,
        border: isHidden ? "1px solid " + (color ? color : GoalStyles[type].color) : "none",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          width: "100%",
          whiteSpace: "nowrap",
          alignItems: "center",
          justifyContent: "center",
          textShadow: "rgba(0, 0, 0, 0.7) 1px 1px 0px",
          fontSize: GoalStyles[type].fontSize,
          height: GoalStyles[type].height,
          padding: `0px ${GoalStyles[type].padding + 10}px`,
          fontWeight: "bold",
          userSelect: "none",
          color: isHidden && !noBackground ? (color ? color : GoalStyles[type].color) : "white",
        }}
      >
        {name ? name : "unnamed"}
      </div>
    </div>
  );
};
