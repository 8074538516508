import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import ScrollToTop from "utils/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./utils/AuthContext";
require("utils/Extends");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <ScrollToTop />
      <App />
    </AuthContextProvider>
  </BrowserRouter>
);
