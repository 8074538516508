import React, { useState, useRef, useEffect, useCallback } from "react";
import { FlexLeftCol, GoalStyles } from "styles/global-style";
import Ribbon from "components/People/Ribbon";
import Date from "components/People/Date";
const clone = require("rfdc")();

export default function DatesGoals({ dates, goals, size, space_x, space_y, animationDone, addGoalCb, addPersonCb, editGoalCb, showParentCb, hideAddButton }) {
  const [loading, setLoading] = useState(true);
  const [goalsArr, setGoalsArr] = useState();
  const [height, setHeight] = useState();
  const [highlightDateIndex, setHighlightDateIndex] = useState(-1);

  const monthNameRef = useRef();
  const hoverContainerRef = useRef();
  const animationFrameId = useRef(); // minimize mouse move re-renders
  const timelineMenuOpen = useRef(false);
  const firstDayIndexes = useRef([]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const buildGoals = useCallback(() => {
    // Build Ribbon positioning array, wait with y position
    let arr = [];
    let maxHeight = 0;
    const monthYearBoxHeight = 22; // Adjust based on the actual height of the month/year box

    // Find x positions for the 1st day of each month
    firstDayIndexes.current = [];
    let latestMonth = null;
    for (let k = 0; k < dates?.length; k++) {
      const currMonth = dates[k].date.getMonthName();
      if (latestMonth !== currMonth && dates[k].date.getUTCDate() < 24) {
        firstDayIndexes.current.push({ index: k, monthName: currMonth });
      }
      latestMonth = currMonth;
    }

    for (var i = 0; i < goals?.length; i++) {
      const nameWidth = goals[i].name?.getTextWidth(null, GoalStyles[goals[i].type].fontSize) || 100;
      const goal = goals[i];
      const goalInRange = dates[0].date.getISODate() < goal.date && goal.date < dates[dates.length - 1].date.getISODate();
      const datesIndex = dates?.findIndex((v) => v.date.getISODate() >= goal.date);

      if (!goalInRange) continue;

      // If weekends are hidden, place their goals in their void space between days
      let x = 0;
      if (dates[datesIndex].date.getISODate() !== goal.date) {
        // On weekend
        x = (dates[datesIndex].left + dates[datesIndex - 1].left) / 2;
      } else {
        // Weekdays (or weekends if set to visible in settings)
        x = dates[datesIndex].left;
      }

      arr.push({
        data: goal,
        x: x,
        index: datesIndex,
        width: nameWidth + GoalStyles[goals[i].type].padding * 2,
        height: GoalStyles[goals[i].type].height,
      });
    }

    // Add y component
    for (let j = 0; j < arr.length; j++) {
      let goal = arr[j];
      let y = 0;

      // Check if the current goal overlaps horizontally with the "box" of the 1st day of the month
      const overlapsFirstDayBox = firstDayIndexes.current.some(({ index, monthName }) => {
        const firstDayX = dates[index].left - 10;
        const monthWidth = monthName.getTextWidth(monthNameRef.current, 28) + 10;
        return goal.x < firstDayX + monthWidth && goal.x + goal.width > firstDayX;
      });

      // If it overlaps, apply vertical offset to avoid the month and year "box"
      if (overlapsFirstDayBox) {
        y = monthYearBoxHeight; // Start y position below the month/year "box"
      }

      // Filter relevant stack for overlaps
      const relevantStack = arr.filter((v, index) => {
        if (index < j) {
          // Only consider items that are placed before the current one
          if (v.x < goal.x + goal.width && v.x + v.width > goal.x) {
            return true;
          }
        }
        return false;
      });

      // Determine the possible y positions, starting from 0 or the offset if needed
      let possibleYPositions = [y]; // Start with 0 or offset based on overlap with the month/year "box"

      for (var k = 0; k < relevantStack.length; k++) {
        const rel = relevantStack[k];

        // We add a potential y position just below this ribbon
        possibleYPositions.push(rel.y + rel.height + space_y);
      }

      // Sort possibleYPositions to try the lowest positions first
      possibleYPositions.sort((a, b) => a - b);

      // Check each possible position to see if the current goal would fit
      for (let pos of possibleYPositions) {
        let fits = true;
        for (let overlap of relevantStack) {
          if (pos < overlap.y + overlap.height && pos + goal.height > overlap.y) {
            fits = false;
            break;
          }
        }
        if (fits) {
          y = pos;
          break;
        }
      }

      maxHeight = Math.max(maxHeight, y + goal.height);
      arr[j].y = y;
    }

    setHeight(maxHeight);
    setGoalsArr(arr);
  }, [goals, dates, space_y]);

  const handleEditGoal = useCallback(
    (id) => {
      editGoalCb(id);
    },
    [editGoalCb]
  );

  const handleMoveGoal = useCallback(
    (id, newIndex) => {
      const oldIndex = goalsArr.findIndex((g) => g.id === id);
      let newArr = clone(goalsArr);
      const goal = newArr.splice(oldIndex, 1);
      newArr.splice(newIndex, 0, goal[0]);
      setGoalsArr(newArr);
      editGoalCb(id, newIndex);
    },
    [goalsArr, setGoalsArr, editGoalCb]
  );

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  useEffect(() => {
    // Show + button on dates depending on mouse x position
    const handleMouseMove = (e) => {
      if (timelineMenuOpen.current) return;
      // We use requestAnimationFrame to throttle updates
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      animationFrameId.current = requestAnimationFrame(() => {
        if (hoverContainerRef.current) {
          const rect = hoverContainerRef.current.getBoundingClientRect();
          const xPosition = e.clientX - rect.left;
          // Calculate which div the mouse is over
          let newIndex = -1;
          for (let i = 0; i < dates?.length; i++) {
            const d = dates[i];
            if (d.left - 2 < xPosition && xPosition < d.right + 2) {
              newIndex = i;
              break;
            }
          }
          if (xPosition < 0 || newIndex === -1) {
            setHighlightDateIndex(null);
          } else if (newIndex !== highlightDateIndex) {
            setHighlightDateIndex(newIndex);
          }
        }
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [size, space_x, dates, highlightDateIndex]);

  useEffect(() => {
    buildGoals();
  }, [buildGoals]);

  useEffect(() => {
    if ((dates && goals && goalsArr && size && space_x, height)) {
      setLoading(false);
    }
  }, [dates, goals, goalsArr, size, space_x, height]);

  useEffect(() => {
    if (!loading) showParentCb(true);
  }, [loading, showParentCb]);

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  return (
    <div style={{ display: "inline-block", position: "relative", width: "100%" }}>
      <div
        style={{
          display: "inline-block",
          position: "relative",
          height: loading ? 0 : height + 20,
          marginBottom: -2,
          visibility: loading ? "hidden" : "visible",
        }}
      >
        {goalsArr &&
          goalsArr.map((goal, k) => {
            return (
              <Ribbon
                key={"goal" + goal.data.id}
                id={goal.data.id}
                dateIndex={goal.index}
                dates={dates}
                size={size}
                space_x={space_x}
                animationDone={animationDone}
                containerLeft={hoverContainerRef?.current?.getBoundingClientRect().left}
                name={goal.data.name}
                type={goal.data.type}
                color={goal.data.color}
                x={goal.x}
                y={goal.y}
                z={500 - k}
                onClick={handleEditGoal}
                movedGoalCb={handleMoveGoal}
                disabled={goal.data?.disabled}
              />
            );
          })}
      </div>

      <div ref={hoverContainerRef} style={{ height: size }}>
        {dates?.map((d, j) => {
          const showMonth = firstDayIndexes.current.find((mon) => mon.index === j);
          return (
            <div
              key={"date" + j}
              style={{
                position: "absolute",
                left: d.left,
                width: size,
                userSelect: "none",
              }}
            >
              {showMonth && (
                <FlexLeftCol gap={0} style={{ position: "absolute", transform: "translateY(-100%)", textShadow: "rgb(34, 34, 34) 1px 1px 3px", marginLeft: 1 }}>
                  <h5 style={{ fontSize: 12, marginBottom: -4 }}>{d.date.getFullYear()}</h5>
                  <h4 ref={monthNameRef} style={{ fontSize: 22 }}>
                    {showMonth.monthName}
                  </h4>
                </FlexLeftCol>
              )}
              <Date
                date={d.date}
                showButton={j === highlightDateIndex && !hideAddButton}
                size={size}
                addGoalCb={addGoalCb}
                addPersonCb={addPersonCb}
                dateClickCb={(isOpen) => (timelineMenuOpen.current = isOpen)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
