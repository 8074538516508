import { useState, createContext, useCallback, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import Loading from "pages/Loading";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [supabase, setSupabase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchCreds() {
      fetch("/.netlify/functions/creds", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setSupabase(createClient(data.url, data.key));
        });
    }
    fetchCreds();
  }, []);

  const logout = useCallback(() => {
    supabase.auth.signOut();
  }, [supabase]);

  const getSession = useCallback(async () => {
    let _res = null;
    if (supabase) _res = await supabase?.auth.getSession();
    const { data } = _res;

    if (data.session) {
      const people = await supabase.from("people").select().eq("uid", data.session?.user.id);

      // If for some reason no user from db, logout
      if (!people.data.length) logout();

      const memberships = await supabase.from("memberships").select("*, orgs(*)").eq("person_id", people.data?.[0]?.id);

      // Set current org to user
      const currOrg = memberships.data.find((mem) => {
        return mem.org_id === people.data[0].current_org_id && !["deleted"].includes(mem.status) && !["deleted"].includes(mem.orgs.status);
      });

      const projects = currOrg ? await supabase.from("projects").select("*, settings(*)").eq("org_id", currOrg.orgs.id).order("id", { ascending: true }) : null;

      // Set current project to user
      let currProj = projects?.data.find((proj) => proj.id === people.data[0]?.current_proj_id) || null;
      if (projects && projects.data.length && !currProj) {
        currProj = projects.data.pop();
        await supabase.from("people").update({ current_proj_id: currProj.id }).eq("id", people.data[0].id);
      }

      const image = await supabase.from("images").select("url").eq("ownerid", people.data?.[0]?.id).eq("type", "avatar");
      const signedUrl = image.data?.[0] ? await supabase.storage.from("avatars").createSignedUrl(image.data[0].url, 3600) : null;

      setUser({
        ...people.data[0],
        access_token: data.session.access_token,
        avatar: signedUrl?.data.signedUrl,
        id: people.data?.[0]?.id,
        memberships: memberships.data?.length ? memberships.data : null,
        org: currOrg?.orgs,
        proj: currProj,
        projects: projects?.data,
        created: data.session.user.created_at,
        last_signin: data.session.user.last_sign_in_at,
        role: memberships?.data?.find((org) => org.id === currOrg.id)?.type,
      });
    } else {
      setUser(null);
      setLoading(false);
    }
  }, [supabase, logout]);

  useEffect(() => {
    if (supabase) {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        getSession();
      });

      return () => subscription.unsubscribe();
    }
  }, [supabase, getSession]);

  useEffect(() => {
    if (supabase && user) setLoading(false);
  }, [supabase, user]);

  // CALLBACKS   XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  const roleAccess = useCallback(
    (minimumRole) => {
      const userRoleIndex = ["member", "lead", "manager", "admin", "owner"].indexOf(user.role);
      const minRoleIndex = ["member", "lead", "manager", "admin", "owner"].indexOf(minimumRole);
      return userRoleIndex >= minRoleIndex;
    },
    [user]
  );

  const join = useCallback(
    async (email, password, cb) => {
      const { error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          emailRedirectTo: "https://peepz.netlify.app/auth",
        },
      });

      if (error) cb(error);
    },
    [supabase]
  );

  const login = useCallback(
    async (username, pass, cb) => {
      supabase.auth
        .signInWithPassword({
          email: username,
          password: pass,
        })
        .then((res) => {
          cb(res.error);
        });
    },
    [supabase]
  );

  const requestPasswordRecovery = useCallback(
    (email, cb) => {
      supabase.auth
        .resetPasswordForEmail(email, {
          redirectTo: "http://peepz.netlify.app/password",
        })
        .then((res) => {
          if (cb) cb(res.error?.message || false);
        });
    },
    [supabase]
  );

  const updatePassword = useCallback(
    (password, cb) => {
      supabase.auth.updateUser({ password: password }).then((res) => {
        if (cb) cb(res.error?.message || null);
      });
    },
    [supabase]
  );

  const updateUser = useCallback(
    (data, cb) => {
      supabase
        .from("people")
        .update(data)
        .eq("id", user.id)
        .then((res) => {
          getSession();
          if (cb) cb(res.error?.message || null);
        });
    },
    [supabase, user, getSession]
  );

  const setUserMembership = useCallback(
    (status, memId) => {
      supabase
        .from("memberships")
        .update({ status: status })
        .eq("id", memId)
        .then(() => {
          getSession();
        });
    },
    [supabase, getSession]
  );

  const refresh = useCallback(() => {
    getSession();
  }, [getSession]);

  const contextValues = {
    supabase,
    user,
    login,
    logout,
    join,
    requestPasswordRecovery,
    updatePassword,
    updateUser,
    setUserMembership,
    roleAccess,
    refresh,
  };

  return (
    <>
      <AuthContext.Provider value={contextValues}>
        {!loading && children}
        {loading && <Loading />}
      </AuthContext.Provider>
    </>
  );
};
