import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "utils/AuthContext";
import Button from "components/Button";
import { PADDING } from "styles/global-style";
import { useLocation } from "react-router-dom";

export default function AuthSettingsMenu() {
  const { roleAccess } = useContext(AuthContext);
  const location = useLocation();

  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage(location.pathname.split("/").at(-1));
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: PADDING / 2,
        paddingRight: PADDING * 3,
      }}
    >
      <Button text="Account" click="/auth/settings/account" menuitem noborder active={["account", "password"].includes(page)} />
      {roleAccess("manager") && <Button text="Project" click="/auth/settings/proj" menuitem noborder active={page === "proj"} />}
      {roleAccess("manager") && <Button text="Organization" click="/auth/settings/org" menuitem noborder active={page === "org"} />}
    </div>
  );
}
