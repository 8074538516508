import React, { useState } from "react";

export default function KeyboardShortcut({ type, text, large, onClickCb }) {
  const [hover, setHover] = useState(false);
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        height: 14,
        columnGap: 8,
        color: "#ffffff55",
        padding: "4px 6px",
        fontSize: 11,
        fontWeight: 600,
        border: "1px solid #ffffff2b",
        borderRadius: 4,
        userSelect: "none",
        transform: large ? (hover && onClickCb ? "scale(2.05)" : "scale(2)") : hover && onClickCb ? "scale(1.05)" : "none",
        transformOrigin: large ? "left" : "none",
        cursor: onClickCb ? "pointer" : "default",
        transition: "all 80ms ease-out",
      }}
      onClick={onClickCb}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {type === "comboenter" && (
        <>
          <span style={{ marginTop: -1, color: "#ffffff88" }}>{text}</span> <span style={{ marginTop: -1 }}>{isMac ? "⌘" : "ctrl"} + ⏎</span>
        </>
      )}
      {type === "tab" && (
        <>
          <span style={{ marginTop: -1, color: "#ffffff88" }}>{text}</span> <span style={{ fontSize: 13, fontWeight: 600 }}>⇥</span>
        </>
      )}
    </div>
  );
}
