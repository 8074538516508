import React from "react";
import { RADIUS } from "styles/global-style";

export default function ColorButton({ color, active, clickCb }) {
  return (
    <div
      onClick={() => clickCb(color)}
      style={{ height: 20, width: 20, background: color, borderRadius: RADIUS, cursor: "pointer", border: "2px solid white", outline: active ? "2px solid #999" : "none" }}
    />
  );
}
